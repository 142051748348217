import React from "react";
import {Switch, Route, Redirect, Router} from "react-router-dom";
import {Layout} from "antd";
// core components
import Navbar from "../components/Navbars/Navbar.js";
import {PrivateRoute} from "../components/Auth/PrivateRoute.js";
import  cFooter from "../components/Footer/Footer.js";

import routes from "../routes.js";
import ErrorHandler from "../components/util/ErrorHandler";



const {Header,Content,Footer} = Layout



const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/uibuilder") {


        return (
          <PrivateRoute
            path={prop.layout + prop.path }
            component={(props) => <prop.component params={props.match.params}></prop.component>}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/uibuilder" to="/uibuilder/build" />
  </Switch>
);



export default function SmileCatalog({ match,...rest }) {
  // styles


  return (
   <Layout className={"layout"}>
     <Header theme="dark">
       <Navbar
           routes={routes}
           params={match.params}

           {...rest}
       />
     </Header>

     <Content style={{ padding: '0 50px' ,minHeight: "calc(100vh - 135px)"}}>
         <ErrorHandler>
                <div className={"site-layout-content"}>{switchRoutes}</div>
         </ErrorHandler>
     </Content>

     <Footer style={{ textAlign: 'center' }}>
         &copy; {1900 + new Date().getYear()}{" "}
         <a
             href="https://manyos.it"
             target="_blank"

         >
             manyos
         </a>
         , made with love for a perfect customer experience
     </Footer>
   </Layout>





  );
}
