import {Alert, Space, Col, Row,Typography} from 'antd';
import React from "react";
import jp from "jsonpath";
import Linkify from 'react-linkify';

const {Text,Paragraph}=Typography;


export default function MSwitch(props) {
    const {onChange,cValue,hiddenEval}=props;


    React.useEffect(()=> {
        if(!cValue){

        }
    },[props]);



    return(
        <div>




                {
                    (!hiddenEval&& cValue) && (
                        <Alert
                            style={{width:"100%"}}
                            message={
                                <Linkify id={"alert-"+cValue.message}
                                         componentDecorator={(decoratedHref, decoratedText, key) => (
                                             <a target="blank" href={decoratedHref} key={key}>
                                                 {decoratedText}
                                             </a>
                                         )}
                                >{cValue.message}</Linkify>

                            }
                            description={
                                <>
                                    {
                                        (cValue.hasOwnProperty("description") )
                                            ?
                                            cValue.description.split("\n").map((text,k)=>{
                                            return (
                                                <Linkify id={"alert-"+text}
                                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                                        <a target="blank" href={decoratedHref} key={key}>
                                                            {decoratedText}
                                                        </a>
                                                    )}
                                                >{text}</Linkify>)
                                        }):
                                            null

                                    }
                                </>


                            }
                            type={(["info", "error", "warning", "success"].includes(cValue.type.toLowerCase()))?cValue.type.toLowerCase():"error"}
                            showIcon
                        />
                    )
                }





            <div className={"divSpacer"}></div>


        </div>
    )

}

