





 export default async function UseQueryFunction({ url,requestOptions },userManager,history){


        const user = await userManager.getUser();

        try {
            if (user){
                if (requestOptions.hasOwnProperty("headers")){
                    requestOptions.headers.Authorization =  "Bearer " + user.access_token
                }else{
                    requestOptions.headers={"Authorization":  "Bearer " + user.access_token}
                }
            }

            const data = await fetch(url, requestOptions);
            if (data.status && data.status>400) {
                let errData =  await data.json();

                history.replace(history.location.pathname,{
                    errorStatusCode: data.status,
                    errorDetail: JSON.stringify({url, ...errData})},
                );
            }



            const apiData= await data.json();


            return apiData;



        }catch (error){

                console.log(error);

        }


}



