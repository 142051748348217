import React from "react";
import { AuthConsumer } from "./AuthProvider";

import ManyosSpinner from "../ManyosSpinner/ManyosSpinner";
export const Callback = () => (
    <AuthConsumer>
        {({ signinRedirectCallback }) => {
            signinRedirectCallback();
            return <ManyosSpinner/>;
        }}
    </AuthConsumer>
);
