import React from "react";
import { AuthConsumer } from "./AuthProvider";

import ManyosSpinner from "../ManyosSpinner/ManyosSpinner";
export const SilentRenew = () => (
    <AuthConsumer>
        {({ signinSilentCallback }) => {
            signinSilentCallback();
            return <ManyosSpinner/>;
        }}
    </AuthConsumer>
);
