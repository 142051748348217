
import React from "react";





export default function MSwitch(props) {
    const {config}=props;




    return(
        <iframe src={config.url} style={{height:"100%",width:"100%" ,border:"none",minHeight:`${window.screen.height-370}px`, margin:0,padding:0 }}/>
    )

}

