import React from "react";
import {Transfer, Table, Col, Spin, Typography} from 'antd';
import difference from 'lodash/difference';
import Placeholder from "../Placeholder/Placeholder";
import _ from "lodash";
import {checkRunQuery, reqBody, scriptName} from "../util/componentUtils";
import useQuery from "../util/useQuery";
import {QuestionCircleOutlined} from "@ant-design/icons";

const {Text}=Typography;

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
    <Transfer {...restProps}>
        {({
              direction,
              filteredItems,
              onItemSelectAll,
              onItemSelect,
              selectedKeys: listSelectedKeys,
              disabled: listDisabled,
          }) => {
            const columns = direction === 'left' ? leftColumns : rightColumns;

            const rowSelection = {
                getCheckboxProps: item => ({ disabled: listDisabled || item.disabled }),
                onSelectAll(selected, selectedRows) {
                    const treeSelectedKeys = selectedRows
                        .filter(item => !item.disabled)
                        .map(({ key }) => key);
                    const diffKeys = selected
                        ? difference(treeSelectedKeys, listSelectedKeys)
                        : difference(listSelectedKeys, treeSelectedKeys);
                    onItemSelectAll(diffKeys, selected);
                },
                onSelect({ key }, selected) {
                    onItemSelect(key, selected);
                },
                selectedRowKeys: listSelectedKeys,
            };

            return (
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={filteredItems}
                    size="small"
                    style={{ pointerEvents: listDisabled ? 'none' : null }}
                    onRow={({ key, disabled: itemDisabled }) => ({
                        onClick: () => {
                            if (itemDisabled || listDisabled) return;
                            onItemSelect(key, !listSelectedKeys.includes(key));
                        },
                    })}
                />

            );
        }}
    </Transfer>
);











export default  React.memo((props)=>{

    const {label,onChange,hiddenEval,elPath,id,dataDefinition,dataInputs,configId,cValue,dependOn,required,regex,regexHint,readonly,placeholder,sortBy,hideColumns,reRunDataScriptsTriggert,customers,...rest}=props;
    const [state,setState]=React.useState({
        targetKeys:[],
        tableColumns:[],
        tableData:[]
    })



    const [body,setBody]=React.useState(reqBody(dataDefinition));
    const [firstRender,setFirstRender]=React.useState(true);
    const [firstLoad,setFirstLoad]=React.useState(false);
    const [dependOnVal,setDependOnVal] = React.useState(undefined);
    const [valid,setValid] = React.useState(undefined);

    const [init,setInit]=React.useState(checkRunQuery(dataDefinition,reRunDataScriptsTriggert,firstLoad,dependOn,elPath,dependOnVal,setBody));


    const defaultSort = (a, b) => ( isNaN(a[sortBy]) && isNaN(b[sortBy]) ? (a[sortBy] || '').localeCompare(b[sortBy] || '') : a[sortBy] - b[sortBy]) ;


    const onChangeTable = nextTargetKeys => {

        //setState({ ...state,targetKeys: nextTargetKeys });
        let selectedVals=nextTargetKeys.map(k=>state.tableData && state.tableData[k])

        onChange(selectedVals.map(removeKeyField))

    };

    const removeKeyField=(el)=>{
        const {key,...rest}=el
        return rest
    }
    const calcTableData=(cValue,tableData)=>{

        const selected=cValue?cValue.map(removeKeyField):[];
        const data=tableData.map(removeKeyField);

        const newTableData=data.filter(d=>selected.map(s=>JSON.stringify(s)).indexOf(JSON.stringify(d))<0)



        return [
            ...selected.sort(defaultSort).map((e,i)=>{return {...e,key:i}}),
            ...newTableData.sort(defaultSort).map((e,i)=>{return {...e,key:i+selected.length}})
        ]
    }

    React.useEffect(()=>{

        if ((!cValue || cValue.length===0 ) && required){
            setValid(false)
        }else{
            setValid(true)
        }

       if (cValue){
           setState({
               ...state,
               targetKeys: Array.isArray(cValue)?cValue.map((e,i)=>i):[] ,
               tableData:calcTableData(cValue,state.tableData)
           });

       }


    },[cValue])

    React.useEffect(()=>{


            if (dataInputs && !dataDefinition){
                setState({
                    ...state,
                    targetKeys: cValue ?cValue.map((e,i)=>i):[] ,
                    tableData:calcTableData(cValue,dataInputs),
                    tableColumns: Object.keys(dataInputs[0]).filter(k => k !== 'key').map(k => {

                        return {
                            dataIndex: k,
                            title: k
                        }
                    })
                });



            }



    },[dataInputs])




    const delayedQuery = React.useCallback(
        _.debounce(q => {

            if (dataDefinition){
            setBody(reqBody(dataDefinition,q));

            setInit(true);
            }

        }, 500),
        [], // will be created only once initially
    );
    React.useEffect(()=>{

        if (reRunDataScriptsTriggert){

            setInit(checkRunQuery(dataDefinition,reRunDataScriptsTriggert,firstLoad,dependOn,elPath,dependOnVal,setBody));
        }
    },[reRunDataScriptsTriggert])

    const data = useQuery({
            url:`${window._env_.REACT_APP_API_URL}/v1/catalog/requests/${configId}/executeScript/${scriptName(dataDefinition)}?customerId=${customers}`,
            requestOptions:
                {
                    method:"POST",
                    headers:{"content-type":"application/json"},
                    body:JSON.stringify(body)
                }
        },
        [dependOn,dependOnVal,init,reRunDataScriptsTriggert], init )





    React.useEffect(()=>{

        if (data && data.scriptResult ) {
            setInit(false);
            if (data.scriptResult[0]) {
                setState({
                    ...state, tableColumns: Object.keys(data.scriptResult[0]).filter(k => k !== 'key' && (hideColumns||[]).indexOf(k)===-1).map(k => {

                        return {
                            dataIndex: k,
                            title: k
                        }
                    }),tableData: calcTableData(cValue,data.scriptResult)
                })

            }


        }
    },[data])


    React.useEffect(()=>{

        if (firstRender){

            if (cValue===undefined){
                onChange(undefined);
            }else if(cValue && cValue.value){

                handleQuery(cValue.value)
            }
            setFirstRender(false);
        }

    },[firstRender])
    const handleQuery = (side,q)=>{

        if (side==="left"){
            delayedQuery(q);
        }

    }

    if (hiddenEval) {
        return (
            <Placeholder {...rest}/>
        )
    }else {
        const {targetKeys,tableColumns,tableData} = state;
        return (
            <div>
                <br/>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Text type={(!valid && required)?("danger"):("")} strong>{(!valid && required)?("* " + label):(label)}</Text>

                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Spin spinning={init}>
                        <TableTransfer
                            dataSource={tableData}
                            targetKeys={targetKeys}
                            showSearch={true}
                            onChange={onChangeTable}
                            onSearch={handleQuery}
                            filterOption={(inputValue, item) =>{
                                let found=false
                                Object.keys(item).filter(k=>k!=='key').forEach(k => {
                                    if (typeof item[k] === "string" && item[k].toLowerCase().indexOf(inputValue.toLowerCase())>=0){
                                        found=true
                                    }
                                })
                                return found
                            }
                            }
                            leftColumns={tableColumns}
                            rightColumns={tableColumns}
                        />
                    </Spin>
                </Col>
            </div>


        );
    }
})

