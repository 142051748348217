import React from "react";



import { Row,Col,Button } from 'antd';
import {useTranslation} from "react-i18next";

import Table from './Table';


import {ExportCSV} from "../ExportFile/ExportToCSV";
import ManyosSpinner from "../ManyosSpinner/ManyosSpinner";
import useQuery from "../util/useQuery";
import Placeholder from "../Placeholder/Placeholder";

export default function RequestTable(props) {


    const {dataInputs,dynDataInputs,cValue,configId,expandable,actions,filter,selectable,onChange,hiddenEval,...rest}=props



    const [loading,setLoading]=React.useState(false);
    const [restInput,setRestInput]=React.useState(undefined);

    const [exportData,setExportData] = React.useState([]);

    const [tableData,setTableData]=React.useState(()=>dynDataInputs?dynDataInputs:dataInputs);


    const {t}=useTranslation();


    React.useEffect(()=>{

        if (dynDataInputs && dynDataInputs.datasource){
            setExportData(dynDataInputs.datasource)
            setTableData(dynDataInputs)
        }else if (dataInputs && dataInputs.datasource){
            setExportData(dataInputs.datasource)
            setTableData(dataInputs)
        }
    },[dataInputs,dynDataInputs])

    function ExpandedRow(props){


        const {expandConf,val}= props;

        const params={
            params:{
                ...expandConf.dataScript.params,
                currentValue:{value:val}
            },

        }

        const resultExecuteOnLoad=useQuery({url:`${window._env_.REACT_APP_API_URL}/v1/catalog/requests/${configId}/executeScript/${expandConf.dataScript.id}`,requestOptions:{method:"POST",headers:{"content-type":"application/json"},body:JSON.stringify({...params,requestData:{}})}},[props],expandConf.dataScript.hasOwnProperty("id"))

        if ((resultExecuteOnLoad) && (resultExecuteOnLoad.hasOwnProperty("scriptResult"))){
            return(<Table  dataSource={resultExecuteOnLoad.scriptResult.datasource} columns={resultExecuteOnLoad.scriptResult.columns}
                           pagination={false}
            />)
        }else{
            return <ManyosSpinner/>
        }

    }


    const expandedRowRender = (record)=>{


        return <ExpandedRow val={record[expandable.value]} expandConf={expandable}/>


    }
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {

            onChange(selectedRows);
        },
        selectedRowKeys:cValue&& cValue.map(row=>row.key)
    };


    React.useEffect(()=>{
        if (tableData){
            const {columns,datasource,onChange,...rest}=tableData
            setRestInput({...rest})
        }


    },[tableData])


    if (hiddenEval){
        return (
            <Placeholder {...rest}/>
        )
    }else {
        return (

            <div>

                {
                    (tableData)
                        ? (
                            <>
                                <Row gutter={[16, 16]} align={"bottom"} justify={"end"}>


                                    <Col xs={24} sm={24} md={24} xl={24}>
                                        {((tableData && tableData.hasOwnProperty("datasource") && !loading)) &&
                                        <>
                                            <ExportCSV key={"export-" + rest.elPath}
                                                       style={{float: "right", marginBottom: "15px", marginLeft: "10px"}}
                                                       reqId={configId} tableData={exportData}/>

                                        </>
                                        }

                                    </Col>

                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} sm={24} md={24} xl={24}>
                                        {


                                            (dataInputs && tableData.hasOwnProperty("datasource") && tableData.hasOwnProperty("columns") && !loading)
                                                ? (

                                                    <>

                                                        <Table key={"table-" + rest.elPath} dataSource={tableData.datasource}
                                                               columns={tableData.columns}
                                                               onChange={(data) => {
                                                                   console.log(data);
                                                                   setExportData(data)
                                                               }

                                                               }
                                                               expandable={expandable && {expandedRowRender}}
                                                               actions={actions && actions}
                                                               rowSelection={selectable && {
                                                                   type: selectable,
                                                                   ...rowSelection
                                                               }}
                                                               {...rest} {...restInput}

                                                        />
                                                    </>
                                                )
                                                : (<ManyosSpinner/>)
                                        }
                                    </Col>

                                </Row>
                            </>
                        )
                        : (
                            <Row gutter={20}>
                                <Col span={12} offset={6}>
                                    <div/>
                                </Col>
                            </Row>
                        )
                }
            </div>
        );
    }
}
