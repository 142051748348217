
import {
    createAsyncThunk
} from "@reduxjs/toolkit";
import {useHistory} from "react-router-dom";

import {getSearchPath, translateParams,debugLog} from "../componentUtils";
import jp from "jsonpath";
import {notification} from "antd";


async function setFieldsWithAttribute({state,searchAttribute,targetAttribute,newValue}){
    const allFieldsWithAttribute= jp.query(state,`$..[*][?(@.${searchAttribute})]`);
    await Promise.all(allFieldsWithAttribute.map(field=>{

        jp.apply(state.requestDefinition.instances,`$..${field.id}`,(value)=>{

            debugLog(`Set ${targetAttribute} for ${field.id} to ${newValue}`)
            return {...value,[targetAttribute]: newValue}


        })

    }))
    return state

}

export function smileCatalogAPIcall({ url,requestOptions,userManager,history }){




    return userManager.getUser().then(user=>{
            if (user){
                if (requestOptions.hasOwnProperty("headers")){
                    requestOptions.headers.Authorization =  "Bearer " + user.access_token
                }else{
                    requestOptions.headers={"Authorization":  "Bearer " + user.access_token}
                }
            }

           return fetch(url, requestOptions)
                    .then(async data => {

                            if (data.status && data.status>400 && Number.isInteger(data.status)){
                                let error = await data.json();

                                history.replace(history.location.pathname, {
                                    errorStatusCode: data.status,
                                    errorDetail: JSON.stringify({url,...error})
                                });
                                //throw Error(JSON.stringify(error));
                            }else{

                                return data.json()
                            }

                        }).catch(error=>Error(error))
                    .then(({code, status, ...apiData}) => {

                        if ((code && code > 400) || ({...apiData}.hasOwnProperty("error") && {...apiData}.hasOwnProperty("stackTrace"))) {
                            if (!(code)) {
                                code = 500
                            }
                            const error = {...apiData};

                            history.replace(history.location.pathname, {
                                errorStatusCode: code,
                                errorDetail: JSON.stringify({url,...error})
                            });
                           throw Error(JSON.stringify(error))
                        } else {

                            return apiData ;
                        }
                    }).catch(error => {throw Error(error)});
        }).catch(error => {throw Error((error))})


}


export const getRequest = createAsyncThunk("request/loadRequestInfo",  async ({ url,requestOptions,userManager,history }) => {


    const response = await smileCatalogAPIcall({ url,requestOptions,userManager,history });
    return response;
});

function getNewValues(target,type,val,requestData){

    let path;
    if (type == "dataInputs"){
        path =target
    }else{
        //remove last element (.value)
        path =target.split(".").slice(0,-1).join(".")
    }
    //get currentInstance in Store
    const cInstance=jp.nodes(requestData,path);

    let value;
    if (val && val[0]){
        value=val[0];
    }
    let newVals=[]
    if (cInstance &&cInstance.length>0 ){
        newVals= cInstance.map(i => {
            //all Instances in Store.
            //i.path
            //i.value
            let objAttr={};
            if (type == "dataInputs"){
                objAttr={...i.value,dataInputs:value}
            }else{
                objAttr={...i.value,value:value,onChangeTrigger:true,reRunDataScripts:true}
            }
            return {path:jp.stringify(i.path),value:objAttr}

        })

    }else{
        let objAttr={};
        if (type == "dataInputs"){
            objAttr={dataInputs:value}
        }else{
            objAttr={value}
        }
        newVals=[
            {path:jp.stringify(path).replace(/\[\*\]/g,"[0]"),value:objAttr}
        ]
    }


    return newVals;
};

export const setReqCustomers=createAsyncThunk("request/setReqCustomers", async({customers},thunkAPI)=>{
    try{
        const oldState = JSON.parse(JSON.stringify(thunkAPI.getState().request.values));

        if (oldState && oldState.requestDefinition && oldState.requestDefinition.items){
            const state1=await setFieldsWithAttribute({state:oldState,searchAttribute:"onChangeScripts",targetAttribute:"onChangeTrigger",newValue:true})
            const finalState=await setFieldsWithAttribute({state:state1,searchAttribute:"dataDefinition",targetAttribute:"reRunDataScripts",newValue:true})
            return {values:finalState,customers}
        }else{
            return {customers}
        }

    }catch (e){
        console.error(e)
    }

});

export const runRequestAgainScript = createAsyncThunk("request/requestAgainScript",  async ({ script,configId,id,userManager,history },thunkAPI) => {
   try{
       const customers=thunkAPI.getState().request.customers.map(e=>e.value)[0]
       let requestData=JSON.parse(JSON.stringify(thunkAPI.getState().request.values));
       debugLog("Run requestAgainScript  with id :" + id);
       if (!script.id){
           return {}
       }
       const response = await smileCatalogAPIcall(
           {
               url:`${window._env_.REACT_APP_API_URL}/v1/catalog/requests/${configId}/executeScript/${script.id}?customerId=${customers}` ,
               requestOptions:
                   {
                       method:"POST",
                       headers:{"content-type":"application/json"},
                       body:JSON.stringify({...script,params:{id,...script.params},requestData})
                   },userManager,history
           });
       debugLog(JSON.stringify(response))
       if (response.hasOwnProperty("scriptResult")){
           return response.scriptResult
       }else{
           return {}
       }


   }catch(e){

        return {}
    }

});


export const runChangeScripts = createAsyncThunk("request/runChangeScripts",  async ({ elPath,configId,actions,value,userManager,history },thunkAPI) => {


                try{
                    const customers=thunkAPI.getState().request.customers.map(e=>e.value)[0]
                    let requestData=JSON.parse(JSON.stringify(thunkAPI.getState().request.values));

                    debugLog("Run Change Scripts on Elementpath:" + JSON.stringify(elPath));
                    const results = await Promise.all(actions.map(async action => {


                        const parsedParams = translateParams(action.script.params,elPath);


                        if (!action.script.id){
                            return {results:[],elPath}
                        }
                        const response = await smileCatalogAPIcall(
                            {
                                url:`${window._env_.REACT_APP_API_URL}/v1/catalog/requests/${configId}/executeScript/${action.script.id}?customerId=${customers}` ,
                                requestOptions:
                                    {
                                        method:"POST",
                                        headers:{"content-type":"application/json"},
                                        body:JSON.stringify({...action.script,params:{...parsedParams,currentValue:value},requestData:thunkAPI.getState().request.values})
                                    },userManager,history
                            });

                        if (response.hasOwnProperty("scriptResult")){

                            return await Promise.all(action.resultMapping.map(resultMap=>{

                                return new Promise(resolve=>{

                                    //value returned from script
                                    const val=jp.query(response,`$.${resultMap.val}`);
                                    const target = getSearchPath(resultMap.target,elPath);

                                    const type = resultMap.type;

                                    const newValues=getNewValues(target,type,val,requestData)
                                    resolve( newValues);
                                })


                            }))
                        }else{
                           return await Promise.all(action.resultMapping.map(resultMap=>{
                               return new Promise(resolve=>{

                                   const target = getSearchPath(resultMap.target,elPath);
                                   const type = resultMap.type;
                                   const newValues=getNewValues(target,type,[undefined],requestData)
                                resolve( newValues)
                               })
                            }))
                        }

                    }));

                    return {results,elPath};
                }catch(e){

                    return undefined
                }



});
