import React,{useState} from "react";
import {Button, Drawer, Input, Switch,Space,Slider,Tabs} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";




import fieldKeys from "../../views/UIBuilder/fieldConfig.json";
import Editor from "@monaco-editor/react";
const { TabPane } = Tabs;

export default function FieldProperties(props){

    const {internalId,onClose,visible,onSave,onChange,config}=props;






    return (
    <Drawer
        title={"Field Properties: " + config.type}
        placement="right"
        closable={false}
        onClose={onClose}
        onSave={onSave}
        visible={visible}
        width={500}
    >
        <>
            <div className={"builder-props-label"}>id:</div>
            <Input className="builder-props-field" value={config.id} placeholder={"Id"} onChange={(e)=>onChange("id",e.target.value,internalId)}/>
            <div style={{width:"100%",height:"2px",float:"left"}}></div>
        </>
        <>
            <div className={"builder-props-label"}>Label:</div>
            <Input className="builder-props-field" placeholder={"Label"} value={config.label} onChange={(e)=>onChange("label",e.target.value,internalId)}/>
            <div style={{width:"100%",height:"2px",float:"left"}}></div>
        </>
        {(fieldKeys[config.type].attr.indexOf("cols") > -1) &&
        <>
            <div className={"builder-props-label"}>Spalten:</div>
            <Slider


                min={1}
                max={4}
                className="builder-props-field"
                onChange={(v)=>onChange("cols",v,internalId)}
            />
            <div style={{width:"100%",height:"2px",float:"left"}}></div>
            </>
        }
        {(fieldKeys[config.type].attr.indexOf("min") > -1) &&
        <>
            <div className={"builder-props-label"}>Min:</div>
            <Slider


                min={1}
                max={10}
                className="builder-props-field"
                onChange={(v)=>onChange("min",v,internalId)}
            />
            <div style={{width:"100%",height:"2px",float:"left"}}></div>
        </>
        }
        {(fieldKeys[config.type].attr.indexOf("max") > -1) &&
        <>
            <div className={"builder-props-label"}>Max:</div>
            <Slider


                min={1}
                max={100}
                className="builder-props-field"
                onChange={(v)=>onChange("max",v,internalId)}
            /> <div style={{width:"100%",height:"2px",float:"left"}}></div>
        </>
        }
        { ( fieldKeys[config.type].attr.indexOf("required")>-1)&&
        <>
            <div className={"builder-props-label"}>Pflichtfeld:</div>
            <Switch
                checked={!!config.required}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                className="builder-props-switch"
                onChange={(v)=>onChange("required",v,internalId)}
            /> <div style={{width:"100%",height:"2px",float:"left"}}></div>
        </>}
        { (fieldKeys[config.type].attr.indexOf("readonly")>-1)&&
        <>

            <div className={"builder-props-label"}>Readonly:</div>
            <Switch
                checked={!!config.readonly}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                className="builder-props-switch"
                onChange={(v)=>onChange("readonly",v,internalId)}
            /> <div style={{width:"100%",height:"2px",float:"left"}}></div>
        </>}
        { (fieldKeys[config.type].attr.indexOf("regex")>-1)&&
        <>
            <div className={"builder-props-label"}>RegEx:</div>
            <Input className="builder-props-field" value={config.regex} placeholder={"Regular Expression"} onChange={(e)=>onChange("regex",e.target.value,internalId)}/>
            <div style={{width:"100%",height:"2px",float:"left"}}></div>
        </>}
        { (fieldKeys[config.type].attr.indexOf("hidden")>-1)&&
        <>
            <div className={"builder-props-label"}>Hidden:</div>
            <Input className="builder-props-field" value={config.hidden} placeholder={"Hidden Expression"} onChange={(e)=>onChange("hidden",e.target.value,internalId)}/>
            <div style={{width:"100%",height:"2px",float:"left"}}></div>
        </>}
        { ((fieldKeys[config.type].attr.indexOf("dataInputs")>-1) || (fieldKeys[config.type].attr.indexOf("dataDefinition")>-1)) &&
        <>
        <Tabs defaultActiveKey="1" >
            {
                (fieldKeys[config.type].attr.indexOf("dataInputs")>-1) &&
                <TabPane tab="Static Data" key="1">
                    <div className={"builder-props-label"}>Datainputs:</div>
                    <Editor
                        height="10vh"
                        theme="vs-dark"
                        defaultLanguage="json"
                        defaultValue={JSON.stringify(config.dataInputs || [{id:"",label:""}])}
                        onChange={ (v)=>{onChange("dataInputs",JSON.parse(v),internalId)}}
                    />
                </TabPane>
            }
            {
                (fieldKeys[config.type].attr.indexOf("dataDefinition")>-1) &&
                <TabPane tab="Datascript" key="2">
                    <div className={"builder-props-label"}>data:</div>
                    <Editor
                        height="10vh"
                        theme="vs-dark"
                        defaultLanguage="json"
                        defaultValue={JSON.stringify(config.dataDefinition || {
                            "script": "remedy/ars101",
                            "params": {
                                "query": "'Data Set Id' = \"BMC.Asset\" AND 'AssetLifecycleStatus' < \"Disposed\" AND 'Serial Number' != $NULL$",
                                "queryWithValue": "'Data Set Id' = \"BMC.Asset\" AND 'Serial Number' != $NULL$ AND 'AssetLifecycleStatus' < \"Disposed\" AND 'Name' LIKE \"%${params.currentValue.value}%\"",
                                "form": "AST:BusinessService",
                                "labelField": "Name",
                                "valueField": "Serial Number",
                                "sortOnLabel": true
                            },
                            "scope": "global",
                            "sortOnLabel": true},null,2,2)}
                        onChange={ (v)=>{onChange("dataDefinition",JSON.parse(v),internalId)}}
                    />
                </TabPane>
            }

            </Tabs>
            <div style={{width:"100%",height:"2px",float:"left"}}></div>

        </>}
        <Space>
        <Button type="primary" onClick={()=>onSave(config)}>
            Übernehmen
        </Button>

        <Button type="primary" onClick={onClose}>
            Abbrechen
        </Button>
        </Space>
    </Drawer>);
}
