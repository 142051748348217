import React from "react";
import { useTranslation } from 'react-i18next';
import {Menu, Space,Dropdown,Button} from "antd";

/**
 * @param {string} dateA - a date, represented in string format
 * @param {string} dateB - a date, represented in string format
 */


function template( template, data ){

        return template.replace(
            /\${(\w*)}/g,
            function( m, key ){
                return data.hasOwnProperty( key ) ? data[ key ] : "";
            }
        );
}

const urlAction = (action,record) => {
    const parsed = {};

    Object.keys(action.params).map(key => {

        parsed[key]=template(action.params[key],record);
    });


    return (
        <a href={parsed.url} target={parsed.target?parsed.target:"_self"}>{action.label}</a>
    )
};


export function Action (actions,record) {
    const {t}=useTranslation();
    if (actions ===undefined && record.dynActions){
        if (record.dynActions.length > 1) {

            const menu = (
                <Menu>
                    {
                        record.dynActions.map((action,key)=>(
                            <Menu.Item key={key}>
                                {urlAction(action,record)}
                            </Menu.Item>
                        ))
                    }
                </Menu>
            )
            return (
                <Dropdown key={record.id} overlay={menu}>
                    <a onClick={e => e.preventDefault()}>
                        <Space>
                            {t('menu-choose-action')}

                        </Space>
                    </a>
                </Dropdown>
            )

        } else {
            return (
                <Space size="middle">
                    {record.dynActions.map(action => {
                        let result;
                        try {
                            switch (action.type) {
                                case "url":
                                    result = urlAction(action, record);
                                    break;
                                default:
                                    result = <div/>;
                            }
                            return result;
                        } catch (e) {
                            console.error(e);
                        }

                    })}
                </Space>
            )
        }

    }else {
        if (actions.length > 1) {

            const menu = (
                <Menu>
                    {
                        actions.map((action,key)=>(
                            <Menu.Item key={key}>
                                {urlAction(action,record)}
                            </Menu.Item>
                        ))
                    }
                </Menu>
            )
            return (
                <Dropdown key={record.id} overlay={menu}>
                    <a onClick={e => e.preventDefault()}>
                        <Space>
                            {t('menu-choose-action')}

                        </Space>
                    </a>
                </Dropdown>
            )

        } else {
            return (
                <Space size="middle">
                    {actions.map(action => {
                        let result;
                        try {
                            switch (action.type) {
                                case "url":
                                    result = urlAction(action, record);
                                    break;
                                default:
                                    result = <div/>;
                            }
                            return result;
                        } catch (e) {
                            console.error(e);
                        }

                    })}
                </Space>
            )
        }
    }




}



