import {Switch, Space, Col, Row} from 'antd';
import React from "react";
import jp from "jsonpath";




export default function MSwitch(props) {
    const {label,onChange,cValue}=props;


    React.useEffect(()=> {
       /* if(!cValue){
            onChange();
        }*/
    },[props]);



    return(
        <div>
            <br/>
                <Space>
            {label}
            <Switch

                checked={!!cValue}
                onChange={onChange}

            /></Space>
           <div className={"divSpacer"}></div>


        </div>
    )

}

