import {Input, Typography, Popover, Col} from 'antd';

import React from "react";
import jp from 'jsonpath';
import useQuery from "../util/useQuery";
import {connect} from "react-redux";

import {QuestionCircleOutlined} from '@ant-design/icons'

import {checkRunQuery, checkRegex, reqBody, scriptName, getSearchPath} from "../util/componentUtils";
import _ from "lodash";
import Placeholder from "../Placeholder/Placeholder";


const {TextArea}=Input;
const {Text}=Typography;




export default  React.memo((props)=>{
    const {label,onChange,rows,hiddenEval,elPath,id,dataDefinition,configId,cValue,dependOn,required,regex,regexHint,readonly,placeholder,reRunDataScriptsTriggert,customers,...rest}=props;

    const [dependOnVal,setDependOnVal] = React.useState(undefined);

    const [curVal,setCurrentValue] = React.useState("");

    const [valid,setValid]=React.useState(checkRegex(cValue,regex,required));
    const [body,setBody]=React.useState(reqBody(dataDefinition));
    const [firstLoad,setFirstLoad]=React.useState(false);



    const [init,setInit]=React.useState(checkRunQuery(dataDefinition,reRunDataScriptsTriggert,firstLoad,dependOn,elPath,dependOnVal,setBody));
    const [reload,setReload]=React.useState(false);

    React.useEffect(()=>{

        setCurrentValue(cValue);
    },[cValue])

    const handleQuery = (q)=>{


        setCurrentValue(q);

            delayedQuery(q);



    }
    const delayedQuery = React.useCallback(
        _.debounce(q => {


            onChange(q)




        }, 500),
        [], // will be created only once initially
    );
    React.useEffect(()=>{

        if (reRunDataScriptsTriggert){

            setInit(checkRunQuery(dataDefinition,reRunDataScriptsTriggert,firstLoad,dependOn,elPath,dependOnVal,setBody));
        }
    },[reRunDataScriptsTriggert])

    const data = useQuery(
        {url:`${window._env_.REACT_APP_API_URL}/v1/catalog/requests/${configId}/executeScript/${scriptName(dataDefinition)}?customerId=${customers}`,
            requestOptions:
                {
                    method:"POST",
                    headers:{"content-type":"application/json"},
                    body:JSON.stringify(body)
                }
        },[dependOn,reload,reRunDataScriptsTriggert],init);

    React.useEffect(()=> {
        // DATA (SCRIPT) CHANGED
                    if (data){
                        setFirstLoad(true);
                        setInit(false);
                        if (data.hasOwnProperty("scriptResult")){
                            try{
                                if (data.scriptResult.length>0){
                                    onChange(data.scriptResult[0].value);
                                }else{

                                    onChange(undefined);
                                }
                            }catch(e){
                                onChange(undefined);
                                console.log(e);
                            }
                        }
                    }
    },[data]);



    React.useEffect(()=>{

        //VALUE HAS CHANGED (ANY REQUEST!)

        if (dependOnVal!==undefined && dependOn===undefined){
            onChange(undefined);
        }else{
            if (checkRunQuery(dataDefinition,reRunDataScriptsTriggert,firstLoad,dependOn,elPath,dependOnVal,setBody,data)){

                if (dataDefinition.hasOwnProperty("dependOn")){

                    setDependOnVal(dependOn);
                    setInit(true);
                    setReload(!reload);
                }
            }
        }

    },[dependOn])

    React.useEffect(()=>{


        setValid(checkRegex(cValue,regex,required))

    },[cValue,required])

    const content=(
        <Text code>{regexHint||regex}</Text>
    )

    if (hiddenEval) {
        return (
            <Placeholder {...rest}/>
        )
    }else{
        return(
            <div>
                <br/>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Text type={(!valid && required)?("danger"):("")} strong>{(!valid && required)?("* " + label):(label)}</Text>
                        {(!valid && regex && cValue )&& <Text type={"danger"}> - Eingabe ungültig. <Popover content={content} title="Eingabeformat"><QuestionCircleOutlined /> </Popover></Text>}
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <TextArea
                        placeholder={placeholder||label}
                        value={curVal}
                        disabled={readonly}
                        autoSize={(rows)?({ minRows: rows, maxRows: rows }):(false)}
                        onChange={(e)=>{
                            handleQuery(e.target.value)
                            //onChange(e.target.value)
                        }}
                        style={{ width: '100%' ,minHeight:'44px'}}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}><div className={"divSpacer"}></div></Col>
            </div>
        )
    }

})



