import React, { useState } from 'react';
import {Col,Row} from 'antd';
import FieldProperties from "../../components/FieldProperties/FieldProperties";
import { Menu, Dropdown, Button, Space,Popconfirm } from 'antd';

import fieldKeys from "./fieldConfig.json";

export default function BuildEntry(props){
    const [visible, setVisible] = useState(false);

    const {config,onChange} = props ;

    const [fieldConfig,setFieldConfig]=useState({});

    const [internalId,setInternalId]=useState(0);


    const [requestDefinition, setRequestdefinition] = useState(()=>{
       if (config.hasOwnProperty("id")){
           return config
       }else{
           return {

               "id": "root",
               "type": "block",
               "min": 1,
               "title": "",
               "max": 5,
               "items": []
           }
       }

    });

    function removeElement(k){
        const t={...requestDefinition};
        t.items=requestDefinition.items.filter((i,x)=>x!==k);
        setRequestdefinition(t);
    }


    const handleAddField=(k)=>{
        let t={...requestDefinition};
        let itemConfig = {};
        itemConfig.type=k.key;
        fieldKeys[k.key].attr.forEach(a=>{
          itemConfig[a]=undefined;
        })
        if (k.key==="block"){
            itemConfig.items=[];
        }
        setInternalId(t.items.length)
        t.items.push(itemConfig);
        setFieldConfig(itemConfig);
        setVisible(true);
        setRequestdefinition(t);
    }

    const changeConfig=(k,v,i)=>{

        let t={...requestDefinition};
        t.items[i][k]=v;
        setRequestdefinition(t);
        //onChange(k,v);
    }

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };
    const onSave = (c) => {

        setVisible(false);
    };

    function blockChangeCallback(k,v){
        let t={...requestDefinition};
        t.items[k]=v;
    }

    React.useEffect(()=>{
        if (config.id==="root"){
            onChange("requestDefinition",requestDefinition)
        }else{
            onChange(props.internalId,requestDefinition)
        }

    },[requestDefinition]);


    return (
        <Row>
        <div style={{height:"100%",width:"100%"}}>
            {config.type} - {config.id}<br/>

            <Dropdown
                overlay={<Menu onClick={handleAddField}>
                    {
                        Object.keys(fieldKeys).map(k=>(<Menu.Item key={k}>
                            { fieldKeys[k].label}
                        </Menu.Item>))
                    }

                        </Menu>}
                placement="bottomLeft"
                >
                <Button >Feld hinzufügen</Button>
            </Dropdown>

            <div style={{height:"20px"}}/>


            {
                (requestDefinition && requestDefinition.hasOwnProperty("items")) &&
                (
                    requestDefinition.items.map((i,k)=> {
                        if (i.type!=="block"){
                            return(
                                <Col key={config.id+"-col-"+i} className={"floating-div"} span={
                                    i.hasOwnProperty("cols")
                                        ?i.cols*6
                                        :24

                                }>
                                    <div
                                        key={config.id+"-item-"+i}

                                        onClick={()=>{setFieldConfig(i);setInternalId(k);setVisible(true)}}
                                        className="builder-field"
                                    >
                                        <Popconfirm
                                            placement="topRight"
                                            title={"Dieses Element löschen?"}
                                            onConfirm={(e)=>{
                                                e.stopPropagation();
                                                removeElement(k);
                                            }}
                                            onCancel={(e)=>{
                                                e.stopPropagation();

                                            }}
                                            okText="Ja"
                                            cancelText="Nein"
                                        >
                                            <Button
                                                className={"builder-del-element"}
                                                size={"small"}
                                                shape="circle"
                                                onClick={(e)=>{
                                                    e.stopPropagation();
                                                }}>
                                                X
                                            </Button>
                                        </Popconfirm>

                                        {i.type} - {i.id}
                                    </div>
                               </Col>

                            )
                        }else{
                            return(
                                <Col key={config.id+"-col-"+i} className={"floating-div"} span={
                                    i.hasOwnProperty("cols")
                                        ?i.cols*6
                                        :24

                                }>
                                <div style={{padding:"10px",border:"1px dashed gray",position:"relative"}} key={config.id+"-item-"+i}>
                                    <Popconfirm
                                        placement="topRight"
                                        title={"Dieses Element löschen?"}
                                        onConfirm={(e)=>{
                                            e.stopPropagation();
                                            removeElement(k);
                                        }}
                                        onCancel={(e)=>{
                                            e.stopPropagation();

                                        }}
                                        okText="Ja"
                                        cancelText="Nein"
                                    >
                                        <Button
                                            className={"builder-del-element"}
                                            size={"small"}
                                            shape="circle"
                                            onClick={(e)=>{
                                                e.stopPropagation();
                                            }}>
                                            X
                                        </Button>
                                    </Popconfirm>

                                        <BuildEntry
                                            key={config.id+"-item-"+i}
                                            config={i}
                                            internalId={k}
                                            onClick={()=>{setFieldConfig(i);setVisible(true)}}
                                            onChange={blockChangeCallback}
                                            className="builder-field"
                                        />




                                </div>
                                </Col>

                            )
                        }
                    }

                    )

                )
            }



            {(fieldConfig.hasOwnProperty("type"))&&
            <FieldProperties
                onClose={onClose}
                visible={visible}
                type={"switch"}
                onSave={onSave}
                internalId={internalId}
                onChange={changeConfig}
                config={fieldConfig}
            />}
        </div>
        </Row>
    );
};
