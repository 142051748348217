import React from "react";
import Block from  "./Block";


export default function BlockComp(props){
    const {...rest}=props

    return (
        <Block {...rest}/>
        )
}


