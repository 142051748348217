import React, { useState } from 'react';
import {Input, Upload} from 'antd';
import ImgCrop from 'antd-img-crop';
import {AuthConsumer} from "../../components/Auth/AuthProvider";
import axios from "axios";

const { TextArea } = Input;
export default function Description(props){
    const [file, setFile] = useState(undefined);
    const [token, setToken] = useState("");


    const {onChange,config}=props;

    const onPicChange = (info ) => {

        if (info.fileList.length>0){
            setFile(info.file);
            onChange("image",info.file.name)
        }

    };

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    return (
        <>
            <AuthConsumer>
                {({getUser})=>{
                    getUser().then(user=>{
                      setToken(user.access_token);
                    })
                }}
            </AuthConsumer>
        <div className={"builder-props-label"}>Bild</div>
        <ImgCrop aspect = {300/170} rotate>
            <Upload
                action={window._env_.REACT_APP_API_URL+"/v1/images"}
                headers={{authorization:"bearer "+token}}

                className="image-uploader"
                listType="picture-card"

                onChange={onPicChange}
                onPreview={onPreview}
                onRemove={(file)=>
                {   setFile(undefined);

                    axios.delete(window._env_.REACT_APP_API_URL+"/v1/images/"+file.name,{
                        headers:{authorization:"bearer "+token}
                        }
                    );
                    }}
            >
                {file===undefined &&'+ Upload'}
            </Upload>

        </ImgCrop>
            <p>
                <div className={"builder-props-label"}>id</div>
                <Input  placeholder={"id"} value={config.id} onChange={(e)=>onChange("id",e.target.value)}/>
            </p>
            <p>
                <div className={"builder-props-label"}>Version</div>
                <Input  placeholder={"Version"} value={config.version} onChange={(e)=>onChange("version",e.target.value)}/>
            </p>
            <p>
                <div className={"builder-props-label"}>srdId</div>
                <Input  placeholder={"srdId"} value={config.srdId} onChange={(e)=>onChange("srdId",e.target.value)}/>
            </p>
            <p>
                <div className={"builder-props-label"}>Titel</div>
                <Input  placeholder={"Titel"} value={config.title} onChange={(e)=>onChange("title",e.target.value)}/>
            </p>

            <p>
                <div className={"builder-props-label"}>Beschreibung</div>
                <TextArea  placeholder={"Beschreibung"} value={config.description} rows={5} onChange={(e)=>onChange("description",e.target.value)}/>
            </p>
        </>
    );
};
