import {InputNumber,Typography,Popover} from 'antd';

import React from "react";
import jp from 'jsonpath';
import useQuery from "../util/useQuery";
import {connect} from "react-redux";

import {QuestionCircleOutlined} from '@ant-design/icons'

import {checkRunQuery, checkRegex, reqBody, scriptName, getSearchPath} from "../util/componentUtils";
import Placeholder from "../Placeholder/Placeholder";


const {Text}=Typography;




export default  React.memo((props)=>{
    const {label,onChange,hiddenEval,elPath,id,dataDefinition,configId,cValue,dependOn,required,readonly,min,max,valid,placeholder,reRunDataScriptsTriggert,customers}=props;

    const [dependOnVal,setDependOnVal] = React.useState(undefined);





    const [body,setBody]=React.useState(reqBody(dataDefinition));
    const [firstLoad,setFirstLoad]=React.useState(false);


    const [init,setInit]=React.useState(checkRunQuery(dataDefinition,reRunDataScriptsTriggert,firstLoad,dependOn,elPath,dependOnVal,setBody));
    const [reload,setReload]=React.useState(false);

    React.useEffect(()=>{

        if (reRunDataScriptsTriggert){

            setInit(checkRunQuery(dataDefinition,reRunDataScriptsTriggert,firstLoad,dependOn,elPath,dependOnVal,setBody));
        }
    },[reRunDataScriptsTriggert])

    const data = useQuery(
        {url:`${window._env_.REACT_APP_API_URL}/v1/catalog/requests/${configId}/executeScript/${scriptName(dataDefinition)}?customerId=${customers}`,
            requestOptions:
                {
                    method:"POST",
                    headers:{"content-type":"application/json"},
                    body:JSON.stringify(body)
                }
        },[dependOn,reload,reRunDataScriptsTriggert],init);

    React.useEffect(()=> {
        // DATA (SCRIPT) CHANGED
                    if (data){
                        setFirstLoad(true);
                        setInit(false);
                        if (data.hasOwnProperty("scriptResult")){
                            try{
                                if (data.scriptResult.length>0){
                                    onChange(data.scriptResult[0].value);
                                }else{

                                    onChange(undefined);
                                }
                            }catch(e){
                                onChange(undefined);
                                console.log(e);
                            }
                        }
                    }
    },[data]);



    React.useEffect(()=>{

        //VALUE HAS CHANGED (ANY REQUEST!)

        if (dependOnVal!==undefined && dependOn===undefined){
            onChange(undefined);
        }else{
            if (checkRunQuery(dataDefinition,reRunDataScriptsTriggert,firstLoad,dependOn,elPath,dependOnVal,setBody,data)){

                if (dataDefinition.hasOwnProperty("dependOn")){

                    setDependOnVal(dependOn);
                    setInit(true);
                    setReload(!reload);
                }
            }
        }

    },[dependOn])

    React.useEffect(()=>{




    },[cValue,required])



    if (hiddenEval) {
        return (
            <Placeholder/>
        )
    }else{
        return(
            <div>
                <br/>

                    <>

                        <Text type={(!valid && required)?("danger"):("")} strong>{(!valid && required)?("* " + label):(label)}</Text>
                    </>

                <InputNumber
                    placeholder={placeholder||label}
                    value={cValue}
                    min={min}
                    max={max}
                    disabled={readonly}

                    onChange={(e)=>{

                        onChange(e.target.value)
                    }}
                    style={{ width: '100%' }}
                />
                <div className={"divSpacer"}></div>
            </div>
        )
    }

})



