import React from 'react'
import {Button, Input, Modal} from 'antd'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {DownloadOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

function getFormattedTime() {
    var today = new Date();
    var y = today.getFullYear();
    var m = today.getMonth() + 1;
    var d = today.getDate();
    var h = today.getHours();
    var mi = today.getMinutes();
    var s = today.getSeconds();
    return y + "-" + m + "-" + d + "-" + h + "-" + mi + "-" + s;
}
export const ExportCSV = (props) => {
    const {t}=useTranslation();
    const {tableData,reqId} = props;
    const [data,setData] =  React.useState({});
    const [fileName,setFileName] = React.useState(null);
    const [ok,setOk] = React.useState(false);

    function exportFile(){
            let name=getFormattedTime()+"_"+reqId;
            exportToCSV(data,name);
            setOk(false)
    }


    React.useEffect(()=>{
            let temp=[];
            if (tableData){
                Object.keys(tableData).map(e=>tableData[e]).forEach((row)=>{

                    let rowObj ={}
                    Object.keys(row).forEach((col,k)=> {
                        if (col!=="key"){
                            rowObj[col]=row[col];
                        }

                    })
                    temp.push(rowObj);
                })
                setData(temp);
            }


        },[props]
    )

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return(
        <Button

            icon={<DownloadOutlined />}
            onClick={() => {
                exportFile()
            }}

            {...props}
        >
            {t('asset-export-button')}
        </Button>
    )
}







