import React from "react";

export default function Placeholder(props){

    const {fixedPosition}=props;

    if (fixedPosition){
        return (
            <div style={{width:'100%', minHeight:'70px'}}/>
        )
    }
    else{
        return null
    }
}
