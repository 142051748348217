import React from "react";


import {Card, Button, Row, Col, Menu, Dropdown} from "antd";
import {PlusOutlined,MinusCircleOutlined,CopyOutlined } from "@ant-design/icons";
import jp from "jsonpath";
import { Popconfirm, message } from 'antd';
import {useTranslation} from "react-i18next";
import {connect,useDispatch} from "react-redux";
import {changeField, setInstances,triggerChangeScriptsFor,setDataTrigger} from "../util/redux";
import {runChangeScripts} from "../util/redux/asyncActions";
import TextField from "../TextField/TextField";
import MultiSelect from "../MultiSelect/MultiSelect";
import MSwitch from "../Switch/Switch";
import DateRange from "../DateRange/DateRange";
import MSelect from "../MSelect/MSelect";
import Block from "./index";
import RadioGroup from "../RadioGroup/RadioGroup";
import CheckBox from "../CheckBox/CheckBox";
import DateField from "../DateField/DateField";
import TableTransfer from "../TableTransfer/TableTransfer";
import RequestTable from "../Table/RequestTable";
import Attachment from "../Attachment/Attachment";
import Alert from "../Alert/Alert";
import {
    getSearchPath,
    escapeRegExp,
    evalQualification,
    scriptName,
    reqBody,
    getValFromStore,
    debugLog
} from "../util/componentUtils";
import store from "../../components/util/redux/index";
import NumericField from "../NumericField/NumericField";
import useQuery from "../util/useQuery";
import {AuthContext} from "../Auth/AuthProvider";
import {useHistory} from "react-router";





const comp = {
    text:TextField,
    multiselect:MultiSelect,
    switch:MSwitch,
    rangepicker:DateRange,
    select:MSelect,
    numeric:NumericField,
    block:Block,
    radiogroup:RadioGroup,
    checkbox:CheckBox,
    datefield:DateField,
    alert:Alert,
    attachment:Attachment,
    transfer:TableTransfer,
    table:RequestTable

}

const fieldSelector = (state,{id,elPath,hidden,dataDefinition,onChangeScripts,onChangeScriptsQualification,required,readonly,dataInputs,defaultValue,triggerChangeScriptsOn}) => {
    let sProps={};
    if (typeof state.request.values === "undefined"){
        return sProps;
    }

    let storeObj


    try{
        storeObj=jp.query(state.request.values,elPath)[0];


    }catch(e){

    }

    sProps.customers=state.request.customers.map(e=>e.value)[0];

        if (!hidden){
            sProps.hiddenParsedQual=false;
        }else{

            if (elPath ){
                sProps.hiddenParsedQual=evalQualification(state.request.values,hidden,elPath)

            }
        }
    if (!readonly){
        sProps.readOnlyParsedQual=false;
    }else if (typeof readonly === "boolean" && readonly ){
        sProps.readOnlyParsedQual=readonly;
    }else{

        if (elPath ){
            sProps.readOnlyParsedQual=evalQualification(state.request.values,readonly,elPath)
            console.log("readonly",readonly,sProps.requiredParsedQual);
        }
    }
        if (!required &&  typeof required==="boolean" || required===undefined ) {
            sProps.requiredParsedQual = false;
        }else if (typeof required==="boolean" && required){
            sProps.requiredParsedQual = true;
        }else{

            if (elPath ){

                sProps.requiredParsedQual=evalQualification(state.request.values,required,elPath)

            }

        }

        //check qualification


        if (triggerChangeScriptsOn && Array.isArray(triggerChangeScriptsOn)){

            sProps.triggerChangeScripts=triggerChangeScriptsOn.map(t => (
                getSearchPath(t,elPath)
            ))

        }

        const triggert=storeObj && storeObj.onChangeTrigger

    if (onChangeScripts && onChangeScriptsQualification && triggert){

        sProps.runOnChangeScripts=evalQualification(state.request.values,onChangeScriptsQualification,elPath);


    }else if (onChangeScripts){

        sProps.runOnChangeScripts=false;
    }

        const reRunDataScriptsTriggert=storeObj && storeObj.reRunDataScripts

        if (reRunDataScriptsTriggert){
            sProps.reRunDataScriptsTriggert=true
        }


        if (triggert ){
            sProps.onChangeTriggert=true
        }

        const dynDataInputs=storeObj && storeObj.dataInputs




        if (dynDataInputs ){
            sProps.dynDataInputs=dynDataInputs;
            sProps.dataInputs = dynDataInputs;
        }


        //get dependOn from values:
        if (!dataDefinition||!dataDefinition.hasOwnProperty("dependOn")){
            sProps.dependOn=undefined;
        }else{
            const searchPath=getSearchPath(dataDefinition.dependOn,elPath);

            sProps.dependOn=jp.query(state.request.values,`${searchPath}`)[0];
        }

        if (defaultValue){
            try{
                const val=getValFromStore(state.request.values,defaultValue,elPath);
                if (val){
                    sProps.defaultV = val
                }else{
                    sProps.defaultV = defaultValue
                }
            }catch(e){
                console.error(e);
                sProps.defaultV = defaultValue
            }


        }

        //get compValue from values:

        sProps.cValue=storeObj && storeObj.value;


        //check if Path exists!


    if (storeObj){
        sProps.init=false;
    }else {
        sProps.init=true;
    }



    return sProps;




}

const Field=(props)=>{
    const dispatch = useDispatch();
    const {customers,onChangeTriggert,triggerChangeScripts,triggerDataScripts,handleFieldChange,id,dataDefinition,defaultV,hiddenParsedQual,cValue,dependOn,type,elPath,configId,runOnChangeScripts,onChangeScriptsQualification,onChangeScripts,init,dataInputs,requiredParsedQual,reRunDataScriptsTriggert,readOnlyParsedQual,...rest} = props;
    const userManager = React.useContext(AuthContext);
    const history=useHistory();
    const [scriptDetails,setScriptDetails] = React.useState({
        init:false,
        scriptName:undefined,
        body:{}
    });

    const [scriptInputs,setScriptInputs]= React.useState(undefined);
    if (init){

        debugLog(`${elPath} init` )

        dispatch(changeField(
            {path:elPath,val:undefined}
        ));


    }
    console.log(cValue,elPath)
    React.useEffect(()=>{

        if (!reRunDataScriptsTriggert){
            if (defaultV) {


                dispatch(changeField(
                    {path: elPath, val: defaultV}
                ));
            }
            if (dataDefinition && dataDefinition.hasOwnProperty("script")){
                setScriptDetails({init:true,scriptName:dataDefinition.script,body:reqBody(dataDefinition,undefined,elPath)})
            }
        }else{
            if (dataDefinition && dataDefinition.hasOwnProperty("script")){

                setScriptDetails({init:true,scriptName:dataDefinition.script,body:reqBody(dataDefinition,undefined,elPath)})
            }
        }



    },[reRunDataScriptsTriggert])

    React.useEffect(()=>{
        if (!(init)){
            if (triggerDataScripts && Array.isArray(triggerDataScripts)){
                triggerDataScripts.forEach(el => {

                    dispatch(setDataTrigger(
                        { elPath:getSearchPath(el,elPath),value:true }
                    ))
                })
            }
        }
    },[cValue])




    React.useEffect(()=>{
        if (runOnChangeScripts){

            dispatch(runChangeScripts(
                { elPath,configId,actions:onChangeScripts,value:cValue,userManager,history }
            ))
        }



    },[runOnChangeScripts,cValue,onChangeTriggert]);



    const data = useQuery({
            url:`${window._env_.REACT_APP_API_URL}/v1/catalog/requests/${configId}/executeScript/${scriptDetails.scriptName}?customerId=${customers}`,
            requestOptions:
                {
                    method:"POST",
                    headers:{"content-type":"application/json"},
                    body:JSON.stringify(scriptDetails.body)
                }
        },
        [scriptDetails], scriptDetails.init &&  scriptDetails.scriptName)



    React.useEffect(()=>{
        if (data && data.hasOwnProperty("scriptResult")){

            setScriptInputs(data.scriptResult);
            setScriptDetails({
                init:false,
                scriptName:undefined,
                body:{}
            })
            dispatch(setDataTrigger({elPath,value:false}))
        }

    },[data])

    const Element = comp[type];


    const changeFunction=(e)=>{



            dispatch(changeField(  {path:elPath,val:e}))


            if (triggerDataScripts && Array.isArray(triggerDataScripts)){
                triggerDataScripts.forEach(el => {

                    dispatch(setDataTrigger(
                        { elPath:getSearchPath(el,elPath),value:true }
                    ))
                })
            }
            if (triggerChangeScripts && Array.isArray(triggerChangeScripts)){
                triggerChangeScripts.forEach(el => {

                    dispatch(triggerChangeScriptsFor(
                        { elPath:getSearchPath(el,elPath) }
                    ))
                })
            }

    }

    return (

        <div className={"element"} key={`div-${elPath}`}>
            {(Element )&&
            <Element key={`Element-${elPath}`}  onChange={changeFunction}
                       hiddenEval={hiddenParsedQual}
                       cValue={cValue||defaultV}
                       type={type}
                     customers={customers}
                       onChangeScripts={onChangeScripts}
                       onChangeScriptsQualification={onChangeScriptsQualification}
                       configId={configId}
                       elPath={elPath}
                     userManager={userManager}
                     history={history}
                     reRunDataScriptsTriggert={reRunDataScriptsTriggert}
                       dataInputs={(scriptInputs)?scriptInputs:(dataInputs?dataInputs:null)}
                       id={id}
                       dependOn={dependOn}
                        dataDefinition={dataDefinition}

                       {...rest}   readonly={readOnlyParsedQual} required={requiredParsedQual} />
            }

        </div>


    );
}

const FieldComp =  React.memo(connect(fieldSelector)(Field))

const selector = (state,{elPath,conf} )=> {
    if (typeof state.request.values==="undefined"){
        return {iCheck:[]};
    }
    if (elPath===undefined){
        return {iCheck:[]};
    }
    return {iCheck:jp.query(state.request.values,`${elPath}.${conf.id}.instances`)}
}


 const  BlockFunc=(props)=>{
     const {id,conf,configId,elPath,iCheck,hiddenEval}=props
    const dispatch = useDispatch();
     const userManager = React.useContext(AuthContext);
     const history=useHistory();
     //blockInfo
     const {t}=useTranslation();

    function removeB(e) {
        const newInstances = blockInfo.instances.filter((v,i)=> i!=e );
        const tmp= {...blockInfo};
        tmp.instances=newInstances;


        const tmpstore  = store.getState().request.values;
        let curInstances = jp.query(tmpstore,`${elPath}.${conf.id}.instances`);
        if (curInstances && curInstances.length>0){
            curInstances=curInstances[0];
        }else{
            curInstances=[];
        }
        curInstances = Object.assign([], curInstances);
        if (e){
            let test = jp.query(tmpstore,`${elPath}`)


            delete curInstances[e]
        }
        curInstances = curInstances.filter( e=> e != null);



        dispatch(setInstances({path:`${elPath}.${conf.id}`,instances:{instances:curInstances}}));


        setBlockInfo({...tmp});

        message.success(t('request-block-remove-success'));
    }

    function cancel(e) {

        message.error(t('request-block-remove-abort'));
    }




    const [blockInfo,setBlockInfo]=React.useState({});

 function createBlockInfo(){


         let config = [];

         let tmp = {...conf};



         let min;

         if (iCheck && iCheck[0] && iCheck[0].length>0){
             min = iCheck[0].length;
         } else if ( tmp &&  tmp.hasOwnProperty("min")) {
             min = conf.min;

         }


         if (tmp && tmp.hasOwnProperty("min")){
             for(let i=0;i<min;i++){
                 tmp = {...conf};

                 tmp.internalId=i;
                 tmp.elPath=`${elPath}.${tmp.id}.instances[${i}]`

                 config.push(tmp);

             }
         }else{
             tmp = {...conf};

             tmp.internalId=0;
             tmp.elPath=`${elPath}.${tmp.id}.instances[0]`
             config.push(tmp);

         }


     return {instances:config}
     //dispatch(setInstances({path:elPath,instances:{instances:config}}));

     }

    React.useEffect(()=>{

        if (createBlockInfo()){
            setBlockInfo(createBlockInfo());
        }


    },[conf])



     Object.unfreeze=function(o){
         var oo=undefined;
         if( o instanceof Array){
             oo=[];var clone=function(v){oo.push(v)};
             o.forEach(clone);
         }else if(o instanceof String){
             oo=new String(o).toString();
         }else  if(typeof o =='object'){

             oo={};
             for (var property in o){oo[property] = o[property];}


         }
         return oo;
     }




     function removeValues(obj) {

        let newO ={};




        Object.keys(obj).forEach(prop=>{
            if ( typeof obj[prop] !== 'object'){
              return;
            }
            else if (Array.isArray(obj[prop]))
                newO[prop]=[]

            else if (typeof obj[prop] === 'object')
                newO[prop]={}

        })

         return newO;

     }

    const handleAdd = (id) => {


        let tmpAry = [...blockInfo.instances];

        let tmp = {...conf};

        tmp.internalId=tmpAry.length;
        tmp.elPath=`${elPath}.${tmp.id}.instances[${tmpAry.length}]`
        tmpAry.push(tmp);


        const tmpstore  = store.getState().request.values;

        let curInstances = jp.query(tmpstore,`${elPath}.${tmp.id}.instances`);
        if (curInstances && curInstances.length>0){
            curInstances=curInstances[0];
        }else{
            curInstances=[];
        }
        curInstances = Object.assign([], curInstances);
        if (id){

            curInstances.push(curInstances[id]);
        }else{

            let itmp = curInstances[0];
             itmp=   Object.assign({}, itmp, removeValues(itmp ));

            curInstances.push(removeValues(itmp ));
        }


        setBlockInfo({instances:tmpAry});
        dispatch(setInstances({path:`${elPath}.${tmp.id}`,instances:{instances:curInstances}}));


    }



    React.useEffect(()=>{

        if (blockInfo.length>0){
            if (elPath!=="$.requestDefinition"){

                //dispatch(setInstances({path:elPath,instances:blockInfo}));
            }



        }


    },[blockInfo])



     if (hiddenEval){
         return null
     }

    return (
        <Row wrap>
            <Col span={24}>
        <Card headStyle={{height:"20px"}} bodyStyle={{padding: "0"}}  key={conf.id} title={conf.title} extra={
            ((blockInfo.hasOwnProperty("instances") && conf.min < conf.max)) &&
            <Dropdown.Button
                overlay={
                    <Menu onClick={(obj)=>{
                        handleAdd(obj.key)
                        if (blockInfo.instances[obj.key] &&blockInfo.instances[obj.key].blockCountChangeScripts){
                            dispatch(runChangeScripts({ elPath:elPath,configId,actions:blockInfo.instances[obj.key].blockCountChangeScripts,value:undefined,userManager,history }))
                        }
                    }}>
                        {
                            blockInfo.instances.map((instance,k)=>{
                                return ( <Menu.Item key={k} icon={<CopyOutlined />}>
                                    {(conf.title || "Instanz" )+": "+ (k+1)}
                                </Menu.Item>);
                            })
                        }
                    </Menu>}
                placement="bottomLeft"
                icon={<CopyOutlined />}
                type="dashed"
                disabled={!(blockInfo.hasOwnProperty("instances") && blockInfo.instances.length < conf.max)}
                onClick={() => {handleAdd()
                    if (blockInfo.instances[0] &&blockInfo.instances[0].blockCountChangeScripts){
                        dispatch(runChangeScripts({ elPath:elPath,configId,actions:blockInfo.instances[0].blockCountChangeScripts,value:undefined,userManager,history }))
                    }
                }}
            >

                {t('request-block-add')}


            </Dropdown.Button>
        }
              bordered={false}
        actions={[
            ((blockInfo.hasOwnProperty("instances") && conf.min < conf.max)) &&
            <div style={{float:"right",    "marginRight": "25px"}}>
                <span style={{marginRight:"5px"}}>{(conf.title || t('request-block-instance-label') )} </span>
                <Dropdown.Button
                overlay={
                    <Menu onClick={(obj)=>{
                        handleAdd(obj.key);

                        if (blockInfo.instances[obj.key] &&blockInfo.instances[obj.key].blockCountChangeScripts){
                            dispatch(runChangeScripts({ elPath:elPath,configId,actions:blockInfo.instances[obj.key].blockCountChangeScripts,value:undefined,userManager,history }))
                        }
                    }}>
                        {
                            blockInfo.instances.map((instance,k)=>{
                                return ( <Menu.Item key={k} icon={<CopyOutlined />}>
                                    {(conf.title || t('request-block-instance-label') )+": "+ (k+1)}
                                </Menu.Item>);
                            })
                        }
                    </Menu>}
                placement="bottomLeft"
                icon={<CopyOutlined />}
                type="dashed"

                disabled={!(blockInfo.hasOwnProperty("instances") && blockInfo.instances.length < conf.max)}
                onClick={() => {handleAdd()
                    if (blockInfo.instances[0] &&blockInfo.instances[0].blockCountChangeScripts){
                        dispatch(runChangeScripts({ elPath:elPath,configId,actions:blockInfo.instances[0].blockCountChangeScripts,value:undefined,userManager,history }))
                    }
                }}
            >
                    {t('request-block-add')}
            </Dropdown.Button></div>


        ]}
        >




        {
            (blockInfo && blockInfo.hasOwnProperty("instances")) &&
            blockInfo.instances.map((block,k)=>{

                let rowClass= "block-bg"+k % 2;
                return(

                    <div key={`block-i-${block.id}-${k}`} className={rowClass}>
                        {(conf.min < conf.max)&&
                        <Col key={"col-block-end-"+k} className={"floating-div "} span={24}><div className="block-end" >
                                      <span >{(conf.title || t('request-block-instance-label') )+": "+ (k+1)}</span>
                                    {
                                        (conf.min &&  k+1>conf.min)
                                       && (<Popconfirm
                                            title={t('request-block-remove-question').replace("${1}",(conf.title || t('request-block-instance-label') )+" "+(k+1))}
                                            onConfirm={() => {


                                                removeB(k)
                                                if (block.blockCountChangeScripts){

                                                    dispatch(runChangeScripts({ elPath:block.elPath,configId,actions:block.blockCountChangeScripts,value:undefined,userManager,history }))
                                                }
                                            }}
                                            onCancel={cancel}
                                            okText={t('yes')}
                                            cancelText={t('no')}>
                                            <Button
                                                type="Link"
                                                type={"dashed"}
                                                danger
                                                size="small"
                                                icon={<MinusCircleOutlined />}
                                            >{t('request-block-remove')} </Button>
                                        </Popconfirm>)
                                    }

                                </div>

                            </Col>

                        }

                    {

                         (block.hasOwnProperty("items"))
                            ? (
                                  block.items.map((item,i) => {

                                     let nPath = `${block.elPath}.${item.id}`;

                                     if (item.type==="block"){

                                         nPath = `${block.elPath}`


                                     }


                                     return (<Col key={`col-b-${k}-${item.id}-${i}`} className={"floating-div" } span={
                                        item.hasOwnProperty("cols")
                                          ?item.cols*6
                                          :24

                                     }><FieldComp  conf={item} elPath={`${nPath}`} configId={configId} key={`b-${k}-${item.id}-${i}`} {...item}
                                     /></Col>)
                                 })
                             )



                            :null


                    }


                </div>
            )})
        }
            </Card>
            </Col>
        </Row>
    )
}

export default React.memo(connect(selector)(BlockFunc));


