
import CatalogEntry from "./views/CatalogEntry/CatalogEntry.js";
import Browse from "./views/Browse/Browse.js";
import AssetEntry from "./views/CatalogEntry/AssetEntry.js";
import UIWizard from "./views/UIBuilder/UIWizard.js";
const dashboardRoutes = [

  {
    path: "/browse",
    name: "header-catalog-button",
    param:"requests",
    component: Browse,
    layout: "/catalog",
    show: (!window._env_.REACT_APP_CATALOG_URL ||window._env_.REACT_APP_CATALOG_URL.length==0 )?true:false
  },

  {
    path: "/request/:id",
    name: "CatalogEntry",


    component: CatalogEntry,
    layout: "/catalog",
    show:false
  },
  {
    path: "/asset/:id",
    name: "AssetEntry",


    component: AssetEntry,
    layout: "/catalog",
    show:false
  },
  {
    path: "/build",
    name: "Build Entry UI",
    component: UIWizard,
    layout: "/uibuilder",
    show:true,
    secure:true
  }






];

export default dashboardRoutes;
