import React,{Suspense} from "react";
import { Card } from 'antd';
import { Row,Col,AutoComplete } from 'antd';
import { Link } from 'react-router-dom'
import {useDispatch} from "react-redux";
import {resetReqState} from "../../components/util/redux/index";
import ManyosSpinner from "../../components/ManyosSpinner/ManyosSpinner";
import lax from "lax.js"
import {useTranslation} from "react-i18next";

import useQuery from "../../components/util/useQuery";


import { Input,Empty,Spin } from 'antd';

const { Meta } = Card;

const { Search } = Input;






export default function Browse(props) {

    const {t}=useTranslation();

    const dispatch = useDispatch();
    dispatch(resetReqState());

    const [values,setValues] = React.useState({});
    const [search,setSearch] = React.useState("");
    const [init,setInit] = React.useState(true);
    const [catalogConfig,setCatalogConfig] = React.useState([]);

    const catalogData=useQuery({url:`${window._env_.REACT_APP_API_URL}/v1/catalog/requests`,requestOptions:{method:"GET",headers:{"content-type":"application/json"}}},[],init);
    const assetData=useQuery({url:`${window._env_.REACT_APP_API_URL}/v1/catalog/assets`,requestOptions:{method:"GET",headers:{"content-type":"application/json"}}},[],init);

    const logoSpin = <object type="image/svg+xml" data="/spinner.svg" style={{    fontSize: "200px"}}/>

    function sortArray(a,b){

        try{
            const valueA = a.catalogItem.title.toUpperCase();
            const valueB = b.catalogItem.title.toUpperCase();
            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }
            return 0;
        }catch (e){
            console.log(e);
        }
    }

    React.useEffect(()=>{
        if (catalogData && assetData){
            setCatalogConfig([...Object.keys(assetData).map(e=>{return {...assetData[e],type:"asset"}}),...Object.keys(catalogData).map(e=>{return {...catalogData[e],type:"request"}})].sort(sortArray));
        }else{
            if (catalogData){

                setCatalogConfig([...catalogConfig,...Object.keys(catalogData).map(e=>{return {...catalogData[e],type:"request"}})].sort(sortArray));
            }
            if (assetData){
                setCatalogConfig([...catalogConfig,...Object.keys(assetData).map(e=>{return {...assetData[e],type:"asset"}})].sort(sortArray));
            }
        }

    },[catalogData,assetData]);

    React.useEffect(()=>{
        if(catalogConfig && catalogConfig.length>0){
            setInit(false);
            lax.init();

            lax.addDriver("scrollY", function () {

                return window.scrollY;
            });

            // Add your elements
            lax.addElements(
                ".laxAnimation",
                {
                    scrollY: {

                        opacity: [
                            ["elInY","elInY+elHeight","elCenterY","elOutY-elHeight","elOutY"],
                            [0,  1,1, 1,0],
                            {
                                easing: 'easeInOutCubic',
                            }
                        ],
                        translateY: [
                            ["elInY","elInY+elHeight","elCenterY","elOutY-elHeight","elOutY"],
                            [50,  1,1, 1,-50],
                            {
                                easing: 'easeInOutCubic',
                            }
                        ],


                    }
                },

            );

        }
    },[catalogConfig])





    const handleFieldChange=(key,val)=>{
        const newObj = values
        newObj[key] = val;
        setValues({ ...newObj })



    }



    const onSearch = (value)  => {
            setSearch(value);
            if (value===""){
                setCatalogConfig([...Object.keys(catalogData).map(e=>{return {...catalogData[e],type:"request"}}),...Object.keys(assetData).map(e=>{return {...assetData[e],type:"asset"}})].sort(sortArray));
            }else{
                setCatalogConfig([...Object.keys(catalogData).map(e=>{return {...catalogData[e],type:"request"}}).filter(e=>e.catalogItem.title.toLowerCase().indexOf(value.toLowerCase())>=0),...Object.keys(assetData).map(e=>{return {...assetData[e],type:"asset"}}).filter(e=>e.catalogItem.title.toLowerCase().indexOf(value.toLowerCase())>=0)].sort(sortArray) );
            }

    }


  return (
    <div>

        <Row gutter={[16,72]} justify={"center"}>
            <Col span={12} >
                <AutoComplete
                    //value={value}
                    //options={options}
                    style={{
                        width: '100%',
                    }}
                    //onSelect={onSelect}
                    onSearch={onSearch}
                  //  onChange={onChange}

                >
                    <Input.Search size="large" placeholder={t('browser-search-placeholder')} enterButton />
                </AutoComplete>
            </Col>
        </Row>






                        {
                            (!init)

                            ?((catalogConfig && catalogConfig.length>0)
                                            ? (



                                        <Row  align={"middle"} justify={"center"} gutter={[16,24]}  >

                                                    {
                                                        catalogConfig.filter(item=>{ return !item.catalogItem.hidden} ).map((e,k)=>(
                                                            <Col key={`col-browse-${k}`} xs={24} sm={24} md={12} lg={8} xl={6} className="gutter-row">
                                                                <Link key={e.catalogId} to={`/catalog/${e.type}/${e.catalogId}`}>
                                                                    <Card
                                                                        key={e.catalogId}
                                                                        className={"laxAnimation"}
                                                                        style={{ width: 300 ,float:"left"}}
                                                                        hoverable={true}
                                                                        cover={
                                                                            (e && e.hasOwnProperty("catalogItem")  && e.catalogItem.hasOwnProperty("image"))
                                                                            ?(<img
                                                                                alt={e.catalogItem.title}
                                                                                src={"/srdImages/"+e.catalogItem.image}
                                                                                style={{height:170}}
                                                                            />)
                                                                            :(<div className="browse-no-pic" >{t('browser-entry-nopicture')}</div>)

                                                                        }
                                                                        actions={[
                                                                            <div to={`/catalog/${e.type}/${e.catalogId}`}> {t('browser-entry-requestnow')}</div>
                                                                        ]}
                                                                    >
                                                                        <Meta

                                                                            title={e.catalogItem.title}
                                                                            description={e.catalogItem.description}
                                                                        />
                                                                    </Card>
                                                                 </Link>

                                                            </Col>
                                                        ))
                                                    }

                                            </Row>


                                            )
                                            :(<Row gutter={[16,24]}><Col span={12} offset={6}><Empty/></Col></Row>)




                                )
                        :(<ManyosSpinner/>)}



    </div>
  );
}
