import React, {useState} from "react";
import { Steps, Button, message,Result } from 'antd';
import BuildEntry from "./BuildEntry";
import Description from "./Description";
import Editor from "@monaco-editor/react";

import Block from "../../components/Block/index";
import useQuery from "../../components/util/useQuery";
import jp from "jsonpath";

const { Step } = Steps;




export default function UIWizard(props){


    const [current, setCurrent] = React.useState(0);
    const [stepState, setStepState] = React.useState([
        "progress",
        "progress",
        "progress",
        "wait"
    ]);




    const handleFieldChange=(path,val)=>{
        let tmp = {...values};
        jp.value(tmp, `${path}.value`, val);

        setValues(tmp);




    }

    const [config,setConfig]=React.useState(()=>{
        if (props.hasOwnProperty("conf")) {
            return props.conf;
        }else{
            return {
                id:"",
                version:"",
                description:"",
                srdId:"",
                title:"",
                image:"",
                requestDefinition:{
                    "id": "root",
                    "type": "block",
                    "min": 1,
                    "title": "",
                    "max": 5,
                    "items": []
                },
                approvals: [
                    "approvalField1",
                    "approvalField2",
                    "approvalField3"
                ],
                entitlementScript: {
                    id: "entitlements/checkITUser",
                    params: {},
                    scope: "global"
                },
                executeOnLoad: [],
                executeBeforeSubmit: [
                    {
                        id: "setApprover",
                        params: {
                            foo: "bar"
                        }
                    }
                ],
                executeAfterSubmit: [
                    {
                        id: "createServiceRequest",
                        scope: "global",
                        params: {}
                    }
                ]
            }
        }
    });
    const [values,setValues] = React.useState(config);
    const handleInstances = (path,instances) => {
        console.log(path);

        let tmp = {...values};
        jp.value(tmp, `${path}`, instances);
        console.log(tmp);
        setValues(tmp);
    }


    const onChange = current => {
        let t = [...stepState];

        setCurrent( current );
    };



    const changeConfig=(k,v)=>{
        let t={...config};
        t[k]=v;
        setConfig(t);

        let st=[...stepState];
        if (config.id.length>0 && config.version.length>0 && config.srdId.length>0 && config.title.length>0 && config.description.length>0){
            st[0]="finish";
        }else{
            st[0]="progress";
        }
        if (config.requestDefinition.items.length>0){
            st[1]="finish";
        }else{
            st[1]="progress";
        }
        if (config.executeAfterSubmit.length>0 && config.entitlementScript.id.length>0){
            st[2]="finish";
        }else{
            st[2]="progress";
        }
        if (st.filter((e,i)=> i<3&&e!=="finish").length===0){
            st[3]="finish";
        }else{
            st[3]="progress";
        }


        setStepState(st);

    }
    const next = () => {

        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const steps = [
        {
            title: 'Beschreibung',
            content: <Description onChange={changeConfig} config={config}/>,
        },
        {
            title: 'Felder definieren',
            content: <BuildEntry onChange={changeConfig} config={config.requestDefinition}/>,
        },
        {
            title: 'Aktionen',
            content: <Editor
                height="50vh"
                theme="vs-dark"
                defaultLanguage="json"
                defaultValue={JSON.stringify(config,null,1,1)}
                onChange={config => setConfig(JSON.parse(config))}
            />,
        },
        {
            title: 'Vorschau und Test',
            content: <>{
                (stepState.filter((e,i)=> i<3&&e!=="finish").length===0)
                    ?(<Block elPath="$.requestDefinition.instances" configId={config.id} key="root" conf={config.requestDefinition}
                             handleFieldChange={handleFieldChange}
                             setInstances={handleInstances}
                             values={values}
                    />):
                    (
                        <Result
                            status="warning"
                            title="Es sind noch nicht alle  Steps abgeschlossen."
                            extra={
                                <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                                    Previous
                                </Button>
                            }
                        />
                    )

            }</>,
        },
    ];

    return (
        <>
            <Steps
                type="navigation"
                current={current}
                onChange={onChange}
                className="site-navigation-steps"
            >
                <Step status={stepState[0]} title="Beschreibung" />
                <Step status={stepState[1]} title="Felder definieren" />
                <Step status={stepState[2]} title="Aktionen" />
                <Step status={stepState[3]} title="Vorschau und Test" />
            </Steps>
            <div className="steps-content">{
                steps[current].content
            }</div>
            <div className="steps-action">
                {current < steps.length - 1 && (
                    <Button type="primary" onClick={() => next()}>
                        Next
                    </Button>
                )}
                {current === steps.length - 1 && (
                    //submit!
                    <Button type="primary" onClick={() => message.success('To be done.')}>
                        Save & Publish
                    </Button>
                )}
                {current > 0 && (
                    <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                        Previous
                    </Button>
                )}
            </div>
        </>
    );
};
