import React, {useContext} from "react";



import { Row,Col,Spin,Result,Collapse,Typography } from 'antd';
import { Button,notification } from 'antd';





import {getRequest, runChangeScripts,setReqCustomers,runRequestAgainScript} from "../../components/util/redux/asyncActions";
import Block from "../../components/Block/index";
import Frame from "../../components/Frame/Frame.js"

import {changeField, initValues, setInstances, loadFromCache, resetReqState} from "../../components/util/redux/index";
import {useSelector,useDispatch,connect} from "react-redux";
import store from "../../components/util/redux/index";
import {useLocation,useHistory} from "react-router-dom";
import queryString from 'query-string';
import {AuthConsumer, AuthContext} from "../../components/Auth/AuthProvider";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import UseQueryFunction from "../../components/util/useQueryFunction";
import ManyosSpinner from "../../components/ManyosSpinner/ManyosSpinner";
import {useTranslation} from "react-i18next";
import {debugLog} from "../../components/util/componentUtils";


const {Panel} = Collapse;
const {Title} = Typography;


const select = state => {



    const finState = {
        valid: state.request.valid,
        spin:state.request.loading
    };

    if (state.request.values && state.request.values.requestDefinition && state.request.values.requestDefinition.items && state.request.values.requestDefinition.items.length>0){
        finState.config= state.request.requestConfig
    }else{
        finState.config=state.request.requestConfig
    }


    return  finState;

};

 const CatalogEntry=(props) =>{

    const {t}=useTranslation();
  const reqId = props.params.id;
  const dispatch=useDispatch();
  const {valid,message,spin,config}=props;
  const q =  useLocation().search;
  const [executeOnLoad,setExecuteOnLoad]=React.useState([]);

  const [submit,setSubmit]=React.useState(false);
  const [storeVals,setStoreVals]= React.useState({});
  const [enableSubmit,setEnableSubmit]=React.useState(false);



  const [init,setInit]=React.useState(true);
  const [customers,setCustomers]=React.useState([]);
  const [resultSubmit,setResultSubmit]=React.useState([]);

  const userManager=useContext(AuthContext);
  const history=useHistory();

  /*React.useEffect(()=>{



      return function cleanup(){
              dispatch(resetReqState());
      }

  },[])*/



    React.useEffect(() => {

            if (init){
                dispatch(resetReqState());
                dispatch(getRequest({url:`${window._env_.REACT_APP_API_URL}/v1/catalog/requests/${reqId}`,requestOptions:{method:"GET",headers:{"content-type":"application/json"}},userManager,history}))

            }


    }, [init])

     React.useEffect(() => {


         if (customers && customers.length==0) {
             userManager.getUser().then(e => {


                 try{
                    const cust=[{
                         value: e.profile.preferred_username,
                         key: e.profile.preferred_username,
                         label: e.profile.name
                     }]

                     dispatch(setReqCustomers({customers:cust}))

                 }catch(e){
                     console.error(e)
                 }


             });
         }else{
             if (customers ){
                 dispatch(setReqCustomers({customers}))
             }


         }





     }, [customers])


    const [showCache,setShowCache]=React.useState(false);

   const [showSubmit,setShowSubmit]=React.useState(true);


    React.useEffect(()=>{

        if (config && config.hasOwnProperty("requestDefinition") ){
            setInit(false);

            dispatch(initValues(config));


            if (config.hasOwnProperty("executeOnLoad")){
                setExecuteOnLoad(config.executeOnLoad)
            }

            if (localStorage.getItem(`{reqInstance:${reqId},version:${config.version}}`)){
                setShowCache(true);

            }
            if ((config.requestDefinition.type==="block")  && ((config.executeBeforeSubmit && Array.isArray(config.executeBeforeSubmit) &&  config.executeBeforeSubmit.length>0 )|| (config.executeAfterSubmit && Array.isArray(config.executeAfterSubmit) &&  config.executeAfterSubmit.length>0 ))){
                setShowSubmit(true)
            }else{
                setShowSubmit(false)
            }


        }


    },[config.id])

     React.useEffect(()=>{

         if (config && config.id){
             try {

                 const qString =queryString.parse(q);
                 Object.keys(qString).forEach(path=>{
                     let val = qString[path]
                     try{
                         val = JSON.parse(qString[path]);
                     }catch(e){
                         console.log("no json string");
                     }
                     if (path=="customers") {
                         //console.log(customers)

                         if (!val.key && val.value) {
                             val.key = val.value;
                         }
                         setCustomers(val)
                     }else if(path=="requestAgain"){
                         console.log("found requestAgain id: "+ val)

                         if (config.requestAgainScript){

                             dispatch(runRequestAgainScript({script:config.requestAgainScript,id:val,configId:config.id,userManager,history}))
                         }

                     }else{
                         dispatch(changeField({val,path:"$.requestDefinition.instances."+path}))
                         console.log({val,path:"$.requestDefinition.instances."+path});
                     }


                 });





             }catch(e){
                 console.log(e);
             }
         }




     },[config.id])

     React.useEffect(()=>{

         setEnableSubmit(valid);


     },[valid]);

    React.useEffect(()=>{

        if (executeOnLoad && executeOnLoad.length>0){

            dispatch(runChangeScripts(
                { elPath:"$.requestDefinition.instances.root.instances[0]",configId:reqId,actions:executeOnLoad,userManager,history }
            ))
        }

    },[executeOnLoad])



     React.useEffect(()=>{
         if (submit){
             const tmpResults=[];
             Promise.all(customers.map(async (customer)=>{

                const result = await UseQueryFunction({url:`${window._env_.REACT_APP_API_URL}/v1/serviceRequests?customerId=${customer.value}`,requestOptions:{method:"POST",headers:{"content-type":"application/json"},body:JSON.stringify({catalogId:reqId,requestData: storeVals})}},userManager,history);
                tmpResults.push(result);

            })).then(r=>{
                     console.log(tmpResults)
                     setResultSubmit(tmpResults);
             }

             )

         }
     },[submit])





    const submitRequest= ()=>{

        setStoreVals(store.getState().request.values);

        setSubmit(true);
    }

    React.useEffect(()=>{

       if  (resultSubmit && resultSubmit.length>0){

           setSubmit(false);


           message("Request übermittelt",`Die Request-ID${(resultSubmit.length>1)?"s lauten":" lautet"}: ${ resultSubmit.map((result)=>(result && result.hasOwnProperty("requestData") ) ? result.requestData.serviceRequestId: null)}`)

           localStorage.removeItem(`{reqInstance:${reqId},version:${config.version}}`)
           //let timer1 = setTimeout(() => window.location.replace("/"), 500)
            history.push("/");
           if (config.redirectToAfterSubmit && config.redirectToAfterSubmit.length>0){
               window.location.replace(config.redirectToAfterSubmit)
               //let timer1 = setTimeout(() => window.location.replace(config.redirectToAfterSubmit), 500)
           }


           // this will clear Timeout when component unmount like in willComponentUnmount




       }



    },[resultSubmit]);



    const loadDataFromCache = () => {
        dispatch(loadFromCache(JSON.parse(localStorage.getItem(`{reqInstance:${reqId},version:${config.version}}`))));
        setShowCache(false);
        notification.close(`{reqInstance:${reqId},version:${config.version}}`)
    }

    const handleInstances = (path,instances) => {

        dispatch(setInstances({path,instances}));

    }


    /*



/*

         if (!(showCache) &&!(init) && reqId && config && config.version){

            // jp.query(values,"$..value").map(e=>{
              //  if(e){
                   // localStorage.setItem(`{reqInstance:${reqId},version:${config.version}}`,JSON.stringify(values));
                }
             })


         }
        //localStorage.setItem(`{reqInstance:${reqId},version:${config.version}}`,JSON.stringify(values));
       // console.log(values);

    },[values])*/

     function requestIds(resultSubmit){


     }

  return (
    <ManyosSpinner spinning={spin}>
        <AuthConsumer>
            {

                ({isAdmin,getUser})=>{

                    if (customers && customers.length==0) {
                        getUser().then(e => {

                                if (e.profile && e.profile.preferred_username) {

                                    setCustomers([{value:e.profile.preferred_username, key:e.profile.preferred_username, label:e.profile.name }]);
                                }

                        });

                    }


               }

            }
        </AuthConsumer>
        {

                (!init)
                  ? (
                    <>
                        {
                            /*
                                                        (showCache) &&
                                                        (
                                                            notification.info({
                                                                message: 'Vom Cache laden',
                                                                description:
                                                                    'Wir haben Daten für diesen Request in Ihrem Cache gefunden. Möchten Sie diese laden?',
                                                                btn:
                                                                    (<Button type="primary" size="small"
                                                                             onClick={loadDataFromCache}>
                                                                        Laden
                                                                    </Button>),
                                                                key: `{reqInstance:${reqId},version:${config.version}}`,
                                                                duration:0,
                                                                onClose:()=>{setShowCache(false)}


                                                            })

                                                        )
                            */
                        }
                        {(config  && config.hasOwnProperty("requestDefinition") &&
                        (config.requestDefinition.type==="block") &&(<Row gutter={[16,16]}>
                            <Col xs={24} sm={24} md={24} xl={24}>
                                    <Col xs={24} sm={24} md={24} xl={24}>
                                        <Collapse   ghost>
                                            <Panel header={<Title level={4} >{config.title}</Title>} key="1" >
                                                <p>{config.description}</p>
                                                { (config.image )?(<><img
                                                        alt={config.title}
                                                        src={"/srdImages/"+config.image}
                                                        style={{position:"absolute",top:0,right:0, height:170,width:300}}
                                                    /><div style={{height:50}}></div></>)
                                                    :(null)}
                                            </Panel>

                                        </Collapse>
                                    </Col>


                            </Col>

                        </Row>))}
                        <Row gutter={[16,16]}>
                            <Col xs={24} sm={showSubmit?16:24} md={showSubmit?20:24} xl={showSubmit?20:24} >
                                {

                                    (config  && config.hasOwnProperty("requestDefinition") &&
                                        (config.requestDefinition.type==="block") ? <Block elPath="$.requestDefinition.instances" configId={reqId} key="root" conf={config.requestDefinition}



                                        ></Block>:
                                            (config.requestDefinition.type==="frame")?<Frame config={config.requestDefinition}/>:null
                                        )
                                }
                            </Col>
                            {(config  && config.hasOwnProperty("requestDefinition") && showSubmit && (

                            <Col xs={24} sm={8} md={4} xl={4}>
                                <div class={"submitButton"}>
                                {
                                (config  &&  config.hasOwnProperty("onBehalfOfScript") &&

                                    <MultiSelect
                                        onChange={(val)=>{


                                            console.log(val);
                                            setCustomers(val);


                                        }}
                                        label={t('request-requestedFor-label')}
                                        dataDefinition={config.onBehalfOfScript}
                                        cValue={customers}
                                        customers={customers && customers[0] && customers[0].value}
                                        reRunDataScriptsTriggert={false}
                                        max={config.onBehalfOfMax}
                                        configId={config.id}
                                        required={true}
                                        hideRefresh={true}
                                    />

                                )}



                                <Button type="primary" block onClick={submitRequest} loading={submit} disabled={!enableSubmit}>{t('request-submit-button')}</Button>
                                </div>
                            </Col>))}
                        </Row>
                    </>
                    )
                :null
        }
    </ManyosSpinner>
  );
}

export default connect(select)(CatalogEntry);
