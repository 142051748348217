import {
    configureStore,
    getDefaultMiddleware,
    createSlice,
} from "@reduxjs/toolkit";

import jp from "jsonpath";
import validator from '@manyos/smilecatalog-utils';

import {getRequest, runChangeScripts, setReqCustomers, runRequestAgainScript} from "./asyncActions";

import _ from 'lodash';


import {debugLog} from "../componentUtils";
import {createLogger} from 'redux-logger';

const logger = createLogger({
   diff:true,
   level: "info"

});


const middleware = [
    ...getDefaultMiddleware(),
    /*YOUR CUSTOM MIDDLEWARES HERE*/


];
if (window._env_.REACT_APP_LOGLEVEL === "DEBUG"){
    middleware.push(logger);
}


const requestState = {
    requestConfig:{},
    loading:false,
    error:{},
    values:{},
    valid:false,
    init:true,
    scriptLoading:false,



};



const requestSlice = createSlice({
    name: "request",
    initialState: requestState,
    reducers: {
        changeField: (state, action) => {

            try{
                if (action.payload.hasOwnProperty("val") && action.payload.hasOwnProperty("path")){

                    let tmp=JSON.parse(JSON.stringify(state.values));
                    debugLog("Setting Path '"+JSON.stringify(action.payload.path) + "' to Value: '"+ JSON.stringify(action.payload.val) +"'");

                    jp.value(tmp, `${action.payload.path}.value`, action.payload.val)
                    debugLog(`Set on ChangeTrigger for ${action.payload.path} to true` )
                    jp.apply(tmp, `${action.payload.path}`, (value)=>( {...value,onChangeTrigger:true}));
                    state.values = tmp;
                    const validation=validator.isValid(tmp);
                    debugLog(`Request Validation result is ${validation}`)
                    state.valid=validation

                }

            } catch(e){
                console.error(e);
            }
            return state;

        },
        setDataTrigger:(state,action)=>{
            try{
                debugLog(`Set on DatascriptTrigger for ${action.payload.elPath} to ${action.payload.value}` )
                jp.apply(state.values, `${action.payload.elPath}`, (value)=>( {...value,reRunDataScripts:action.payload.value}));
            }catch(e){
                console.error(e)
            }
            return state;
        },
        triggerChangeScriptsFor:(state,action)=>{

            try{
                debugLog(`Set on ChangeTrigger for ${action.payload.elPath} to true` )


                jp.apply(state.values, `${action.payload.elPath}`, (value)=>( {...value,onChangeTrigger:true}));

            }catch(e){
                console.error(e);
            }
            return state;



        },
        setInstances: (state,action)=>{
            try{


                jp.value(state.values, `${action.payload.path}`, action.payload.instances);

            }catch(e){
                console.error(e);
            }
            return state;

        },
        initValues:(state,action)=>{
            let tmp= JSON.parse(JSON.stringify({...action.payload}));
            if (!state.hasOwnProperty("values") || !state.values.hasOwnProperty("requestDefinition") || !state.values.requestDefinition.hasOwnProperty("values")){
                tmp.requestDefinition.instances={};
            }else{
                tmp.requestDefinition.instances=state.values.requestDefinition.instances;
            }


            state.values={...tmp};

            return state;
        },
        loadFromCache:(state,action)=>{
            state.values=action.payload;
            return state;
        },
        resetReqState:(state)=> {
            return state = {
                requestConfig:{},
                loading:false,
                error:{},
                values:{},
                valid:false,
                init:true,
                scriptLoading:false,



            };

        },

    },
    extraReducers:{
        [getRequest.pending]: state => {
            state.loading = true;
        },
        [getRequest.rejected]: (state, action) => {
            state.loading = false;
            state.init=false;
            state.error = action.error.message;
        },
        [getRequest.fulfilled]: (state, action) => {
            state.loading = false;
            state.requestConfig = action.payload;
            state.init=false;
        },
        [setReqCustomers.pending]: state => {
            state.loading = true;
        },
        [setReqCustomers.rejected]: (state, action) => {
            state.loading = false;
            state.init=false;
            state.error = action.error.message;
        },
        [setReqCustomers.fulfilled]: (state, action) => {
            state.loading = false;
            state.customers=action.payload.customers;
            if (action.payload.values){
                state.values=action.payload.values;
            }

        },
        [runChangeScripts.pending]: state => {
            state.loading = true;
        },
        [runChangeScripts.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        },
        [runChangeScripts.fulfilled]: (state, action) => {

         //   let tmp =state.values;

            if (action.payload && action.payload.results && Array.isArray(action.payload.results)){


                action.payload.results.forEach(action=>{
                    action.forEach(target=>{
                        target.forEach(field=>{
                            debugLog("Setting Attributes for Path '"+field.path + "' to : '"+ JSON.stringify(field.value) +"'");
                            jp.value(state.values,field.path,field.value)

                        })
                    })
                })


            }

            debugLog(`Set on ChangeTrigger for ${action.payload.elPath} to false` )
            jp.apply(state.values,action.payload.elPath, (value) =>{return  { ...value,onChangeTrigger: false}})
            let tmp=JSON.parse(JSON.stringify(state.values));
            state.loading=false;
            const validation=validator.isValid(tmp);
            debugLog(`Request Validation result is ${validation}`)
            state.valid=validation

        },
        [runRequestAgainScript.pending]: state => {
            state.loading = true;
        },
        [runRequestAgainScript.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        },
        [runRequestAgainScript.fulfilled]: (state, action) => {

            //   let tmp =state.values;


            debugLog(`Setting $.requestDefinition to ${JSON.stringify(action.payload)}`)
            state.values.requestDefinition=action.payload;
            state.requestConfig.requestDefinition=action.payload;

            let tmp=JSON.parse(JSON.stringify(state.values));

            const validation=validator.isValid(tmp);
            debugLog(`Request Validation result is ${validation}`)
            state.valid=validation
            state.loading = false;

        }
    }
});

export const { changeField,setInstances,initValues,loadFromCache,resetReqState,triggerChangeScriptsFor,setDataTrigger } = requestSlice.actions;

const requestReducer = requestSlice.reducer;

export default configureStore({
    reducer: {
        request: requestReducer,
    },
    middleware,
});



