import {Radio , Space,Typography,Col} from 'antd';
import React from "react";
import jp from "jsonpath";
import Placeholder from "../Placeholder/Placeholder";

const {Text} = Typography;

export default function RadioGroup(props) {
    const {label,onChange,cValue,dependOn,hiddenEval,required,dataInputs,button,dynDataInputs,showLineByLine,...rest}=props;


    React.useEffect(()=> {
        if(!cValue){
            onChange({value:undefined});
        }
    },[props]);

    const [valid,setValid]=React.useState(()=>{
       if (cValue && cValue.value){
           return true;
       }else{
           return false
       }
    })

    React.useEffect(()=> {
        if (cValue && cValue.value){
            setValid(true) ;
        }else{
            setValid(false) ;
        }

    },[cValue,required])

    React.useEffect(()=> {
        if(cValue && cValue.value){
            if(!dataInputs.find(e=>e.value===cValue.value)){
                onChange({value:undefined});
            }
        }

    },[cValue,dataInputs])

    if (hiddenEval){
        return (
            <Placeholder {...rest}/>
        )
    }else{
        return(
            <div>
                <br/>

                {
                    (showLineByLine)
                        ?( <div><Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Text type={( required && !valid)?("danger"):("")} strong>{( required && !valid)?("* " + label):(label)}</Text>
                            </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Radio.Group
                                className={showLineByLine && "radioButtonAsLine"}
                                value={cValue && cValue.value}
                                options={dataInputs}
                                optionType={button && "button"}
                                buttonStyle={button && "solid"}
                                onChange={(evt)=>{
                                    setValid(true);
                                    let data;
                                    onChange({value:evt.target.value, label:dataInputs.filter(e=>e.value===evt.target.value)[0].label})
                                }}
                            >

                            </Radio.Group>
                        </Col></div>)
                        :( <Space>
                            <Text type={( required && !valid)?("danger"):("")} strong>{( required && !valid)?("* " + label):(label)}</Text>

                            <Radio.Group
                                value={cValue && cValue.value}
                                options={dataInputs}
                                optionType={button && "button"}
                                buttonStyle={button && "solid"}
                                onChange={(evt)=>{
                                    setValid(true);
                                    let data;
                                    onChange({value:evt.target.value, label:dataInputs.filter(e=>e.value===evt.target.value)[0].label})
                                }}
                            >

                            </Radio.Group>
                        </Space>)
                }

                <div className={"divSpacer"}></div>


            </div>
        )
    }


}

