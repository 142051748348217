import React from "react";


import {useLocation} from 'react-router-dom';
import { Row,Col,Button,Collapse,Typography } from 'antd';
import ManyosSpinner from "../../components/ManyosSpinner/ManyosSpinner";
import {useTranslation} from "react-i18next";

import Table from '../../components/Table/Table';
import {ReloadOutlined} from  "@ant-design/icons";

import useQuery from "../../components/util/useQuery";
import queryString from 'query-string';
import {ExportCSV} from "../../components/ExportFile/ExportToCSV";

const {Panel} = Collapse;
const {Title} = Typography;
export default function AssetEntry(props) {


    const q =  useLocation().search;



    const reqId = props.params.id;

    const [init,setInit]=React.useState(true);
    const [reload,setReload]=React.useState(true);
    const [loading,setLoading]=React.useState(false);

    const [executeOnLoad,setExecuteOnLoad]=React.useState([]);
    const [exportData,setExportData] = React.useState([]);
    const [showPic,setShowPic] = React.useState(false);

    const {t}=useTranslation();



  const config=useQuery({url:`${window._env_.REACT_APP_API_URL}/v1/catalog/assets/${reqId}`,requestOptions:{method:"GET",headers:{"content-type":"application/json"}}},[],init)



    React.useEffect(()=>{
        if (config  ){
            setInit(false);


            if (config.hasOwnProperty("dataScript")){
                setLoading(true);
                let tmp = {...config.dataScript}
                try {

                    const qString =queryString.parse(q);
                    if (Object.keys(qString).length > 0){

                        tmp.params.urlParams=qString;
                        setExecuteOnLoad(tmp);
                    }else{
                        setLoading(true);
                    }
                }catch(e){
                    console.log(e);
                }

                setExecuteOnLoad(tmp)

            }


        }


    },[config,q])





    const resultExecuteOnLoad=useQuery({url:`${window._env_.REACT_APP_API_URL}/v1/catalog/assets/${reqId}/executeScript/${executeOnLoad.id}`,requestOptions:{method:"POST",headers:{"content-type":"application/json"},body:JSON.stringify({params:executeOnLoad.params,scope:executeOnLoad.scope,requestData:{}})}},[executeOnLoad,reload],executeOnLoad.hasOwnProperty("id"))


    React.useEffect(()=>{

       if (resultExecuteOnLoad && resultExecuteOnLoad.hasOwnProperty("scriptResult") && resultExecuteOnLoad.scriptResult.hasOwnProperty("columns")){
           setLoading(false);


           setExportData(resultExecuteOnLoad.scriptResult.datasource)
       };








    },[resultExecuteOnLoad])
    //const resultSubmit=useQuery({url:`${window._env_.REACT_APP_API_URL}/v1/serviceRequests`,requestOptions:{method:"POST",headers:{"content-type":"application/json"},body:JSON.stringify({catalogId:reqId,requestData:values})}},[submit],submit)


    const callbackPanel = (key) => {
        if (key.length>0){
            setShowPic(true);
        }else{
            setShowPic(false);
        }
        console.log(key);
    }



    function ExpandedRow(props){


        const {expandConf,val}= props;

        const params={
            params:{
                ...expandConf.dataScript.params,
                currentValue:{value:val}
            },

        }

        const resultExecuteOnLoad=useQuery({url:`${window._env_.REACT_APP_API_URL}/v1/catalog/assets/${reqId}/executeScript/${expandConf.dataScript.id}`,requestOptions:{method:"POST",headers:{"content-type":"application/json"},body:JSON.stringify({...params,requestData:{}})}},[props],expandConf.dataScript.hasOwnProperty("id"))

        if ((resultExecuteOnLoad) && (resultExecuteOnLoad.hasOwnProperty("scriptResult"))){
           return(<Table  dataSource={resultExecuteOnLoad.scriptResult.datasource} columns={resultExecuteOnLoad.scriptResult.columns}
                          pagination={false}
            />)
        }else{
            return <ManyosSpinner/>
        }

    }


    const expandedRowRender = (record)=>{


        return <ExpandedRow val={record[config.expandable.value]} expandConf={config.expandable}/>


    }
  return (
    <div>

        {
            (!init && config)
                  ? (
                    <>
                        <Row gutter={[16,16]} align={"bottom"} justify={"end"}>


                            <Col xs={24} sm={24} md={showPic?24:19} xl={showPic?24:19}>
                                <Collapse  onChange={callbackPanel} ghost>
                                    <Panel header={<Title level={4} >{config.title}</Title>} key="1" >
                                        <p>{config.description}</p>
                                        { (config.image && showPic)?(<><img
                                                alt={config.title}
                                                src={"/srdImages/"+config.image}
                                                style={{position:"absolute",top:0,right:0, height:170,width:300}}
                                            /><div style={{height:50}}></div></>)
                                            :(null)}
                                    </Panel>

                                </Collapse>
                            </Col>
                            <Col flex={2} xs={24} sm={24} md={5} xl={5}>
                                {( (resultExecuteOnLoad  && resultExecuteOnLoad.hasOwnProperty("scriptResult") && !loading)) &&
                                <>
                                    <ExportCSV style={{float:"right", marginBottom:"15px", marginLeft:"10px"}}  reqId={reqId} tableData={exportData} />
                                    <Button style={{float:"right", marginBottom:"15px"}} icon={<ReloadOutlined/>}  onClick={()=>{setReload(!reload); setLoading(true)}  } loading={loading}>
                                        {t('asset-reload-button')}
                                    </Button>
                                </>
                                }

                            </Col>

                        </Row>
                        <Row gutter={[16,16]}>
                            <Col xs={24} sm={24} md={24} xl={24}>
                                {
                                    (resultExecuteOnLoad  && resultExecuteOnLoad.hasOwnProperty("scriptResult") && !loading)
                                        ?(
                                            <>

                                                <Table  dataSource={resultExecuteOnLoad.scriptResult.datasource} columns={resultExecuteOnLoad.scriptResult.columns} onChange={setExportData}
                                                expandable={config.hasOwnProperty("expandable")&&{expandedRowRender}} actions={config.hasOwnProperty("actions")&&config.actions}
                                                />
                                            </>
                                        )
                                        :(<ManyosSpinner/>)
                                }
                            </Col>

                        </Row>
                    </>
                    )
                :(
                    <Row gutter={20}>
                        <Col span={12} offset={6}>
                            <ManyosSpinner/>
                        </Col>
                    </Row>
                )
        }
    </div>
  );
}
