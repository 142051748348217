import React, {useEffect} from "react";


import { Menu, Dropdown, Button } from 'antd';
import { UserOutlined,LogoutOutlined } from '@ant-design/icons';
import {AuthConsumer,AuthContext} from "../Auth/AuthProvider";
import { useTranslation } from 'react-i18next';

import { matchPath } from "react-router";


import Logo from '../../image/vk-logo-orig.png';


export default function Header(props) {


  const [admin,setAdmin] = React.useState(false);

  const userContext  = React.useContext(AuthContext);

  const [user,setUser] = React.useState("unknown");
  const {t}=useTranslation();

  useEffect(()=>{

    if (userContext && userContext.hasOwnProperty("getUser")){
      userContext.getUser().then(user=>{
        if (user && user.hasOwnProperty("profile") && user.profile.hasOwnProperty("name")){
          setUser(user.profile.name)
          setAdmin(userContext.isAdmin());
        }


      })
    }

  },[userContext])

  function activeRoute(routeName) {
    const match = matchPath(props.location.pathname, {
      path: routeName
    });
    if (match && match.hasOwnProperty("isExact")){
      return (match.isExact)
    }else{
      return false;
    }

  }

  function getRouteParams(routeName) {
    const match = matchPath(props.location.pathname, {
      path: routeName
    });
    if (match && match.hasOwnProperty("params")){
      return match.params;
    }
    else{
      return null;
    }
  }
  function handleMenuClick(e) {
    if (e.key==="logout"){
      window.location.replace("/logout");
    }else{
      console.log('click', e);
    }

  }

  const userMenu = (
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="logout" style={{textAlign:"left"}} icon={ <LogoutOutlined />}>
          {t('menu-logout')}

        </Menu.Item>

      </Menu>
  );
  function MenuEntries(props) {


    return (




        <Menu mode="horizontal" theme="dark" defaultSelectedKeys={['1']} style={{float:"left"}}>
          {
          props.routes.map((prop) => {



          const params = getRouteParams(prop.layout + prop.path);
          if (params && prop.show && activeRoute(prop.layout + prop.path) && params.hasOwnProperty("type") && (((prop.secure === true) && admin) || !(prop.hasOwnProperty("secure")) || prop.secure === false)) {
          return <Menu.Item key={"button-"+prop.name.toLowerCase()} >
          <a href={`${prop.layout}${prop.path.split(":")[0]}${prop.param}`}>{t(prop.name)}</a>
          </Menu.Item>
        }
          if (prop.show && activeRoute(prop.layout + prop.path) && params.hasOwnProperty("type")  && (((prop.secure === true) && admin) || !(prop.hasOwnProperty("secure"))|| prop.secure === false)) {
          return <Menu.Item key={"button-"+prop.name.toLowerCase()} >
          <a href={prop.layout + prop.path.split(":")[0]}>{t(prop.name)}</a>
          </Menu.Item>
        }else if (prop.show && activeRoute(prop.layout + prop.path)  && (((prop.secure === true) && admin) || !(prop.hasOwnProperty("secure") )|| prop.secure === false)) {
          return <Menu.Item key={"button-"+prop.name.toLowerCase()}  href="#" >
          <a href="#">{t(prop.name)}</a>
          </Menu.Item>
        }else if (prop.show && prop.hasOwnProperty("param")  && (((prop.secure === true) && admin) || !(prop.hasOwnProperty("secure") )|| prop.secure === false)) {

          return <Menu.Item key={"button-" + prop.name.toLowerCase()}>
          <a href={`${prop.layout}${prop.path.split(":")[0]}${prop.param}`}>{t(prop.name)}</a>
          </Menu.Item>

        }else if (prop.show  && (((prop.secure === true) && admin) || !(prop.hasOwnProperty("secure")) || prop.secure === false)){

          return <Menu.Item key={"button-"+prop.name.toLowerCase()}  >
          <a href={prop.layout + prop.path.split(":")[0]}>{t(prop.name)}</a>
          </Menu.Item>
        }else{
          return null;
        }

        })}
        </Menu>

    )


  }
  const { color } = props;

  return (
    <div class={"top-menu-holder"}>
      <div  className="logo" />
      {window._env_.REACT_APP_CATALOG_TITLE && <div className={"catalog-title"}>{window._env_.REACT_APP_CATALOG_TITLE}</div>
      }

      <AuthConsumer>
        {
          ({isAdmin,getUser})=>{


              getUser().then(e=>{
                if (e.profile && e.profile.name) {

                  setUser(e.profile.name);
                }

              });



          }
        }
      </AuthConsumer>

      {
        ((window._env_.REACT_APP_CATALOG_URL && window._env_.REACT_APP_CATALOG_URL.length>0 ) || (window._env_.REACT_APP_ACTIVITY_URL &&window._env_.REACT_APP_ACTIVITY_URL.length>0))

        &&  (<Menu mode="horizontal" theme="dark"  style={{float:"left"}}>
          {  (window._env_.REACT_APP_ACTIVITY_URL && window._env_.REACT_APP_ACTIVITY_URL.length > 0) ?
          (<Menu.Item key="KatalogActivity" >
            <a href={`${window._env_.REACT_APP_ACTIVITY_URL}`}>{t('header-activity-button')}</a>
          </Menu.Item>):null
          }
          {
            (window._env_.REACT_APP_CATALOG_URL && window._env_.REACT_APP_CATALOG_URL.length > 0) ?
            ( <Menu.Item key="KatalogManual">
              <a href={`${window._env_.REACT_APP_CATALOG_URL}`}>{t('header-catalog-button')}</a>
            </Menu.Item>):null
          }
            </Menu>)



      }


      <MenuEntries routes={props.routes} admin={admin}/>


      {!window._env_.REACT_APP_CATALOG_URL
      ?(<Menu mode="horizontal" theme="dark" defaultSelectedKeys={['1']} style={{float:"right"}}>
            <Menu.Item>

              <Dropdown overlay={userMenu}>
                <Button >
                  <UserOutlined /> {user}
                </Button>
              </Dropdown>
            </Menu.Item>
          </Menu>)
          : <div className={"header-userName"} ><UserOutlined /> {user}</div>
      }

    </div>
  );
}


