import React from "react";
import { Route } from "react-router-dom";
import { AuthConsumer } from "./AuthProvider";

import ManyosSpinner from "../ManyosSpinner/ManyosSpinner";

export const PrivateRoute = ({ component, ...rest }) => {
    const renderFn = (Component) => (props) => (
        <AuthConsumer>
            {({ isAuthenticated, signinRedirect }) => {


                if (!!Component && isAuthenticated()) {
                    return <Component {...props} />;
                } else {

                    signinRedirect();
                    return <ManyosSpinner/>;
                }
            }}
        </AuthConsumer>
    );

    return <Route {...rest} render={renderFn(component)} />;
};
