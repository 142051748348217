import {Select, Button, Row, Col, Typography} from 'antd';
import React from "react";
import useQuery from "../util/useQuery";
import {ReloadOutlined} from  "@ant-design/icons";
import Placeholder from "../Placeholder/Placeholder";

import {checkRunQuery, reqBody, scriptName} from "../util/componentUtils";
import _ from "lodash";

const {Option}=Select;

const {Text}=Typography;

export default  React.memo((props) =>{
    const {label,onChange,cValue,dependOn,hiddenEval,dataDefinition,configId,dataInputs,elPath,required,dynDataInputs,hideRefresh,placeholder,max,reRunDataScriptsTriggert,customers,...rest}=props;
    const [curVal,setCurrentValue] = React.useState("");
    const [dependOnVal,setDependOnVal] = React.useState(undefined);
    const [reload,setReload]=React.useState(false);
    const [firstRender,setFirstRender]=React.useState(true);
    const [firstLoad,setFirstLoad]=React.useState(false);
    const [body,setBody]=React.useState(reqBody(dataDefinition));


    const [init,setInit]=React.useState(checkRunQuery(dataDefinition,reRunDataScriptsTriggert,firstLoad,dependOn,elPath,dependOnVal,setBody));


        const handleQuery = (q)=>{
            setCurrentValue(q);

            delayedQuery(q);
        }

        React.useEffect(()=>{

                if (firstRender){

                    if (cValue===undefined){
                        onChange(undefined);
                    }else if(cValue && cValue.value){

                        handleQuery(cValue.value)
                    }
                    setFirstRender(false);
                }

        },[firstRender])

        const delayedQuery = React.useCallback(
            _.debounce(q => {

                setBody(reqBody(dataDefinition,q));

                setInit(true);

            }, 500),
            [], // will be created only once initially
        );

    React.useEffect(()=>{

        if (reRunDataScriptsTriggert){

            setInit(checkRunQuery(dataDefinition,reRunDataScriptsTriggert,firstLoad,dependOn,elPath,dependOnVal,setBody));
        }
    },[reRunDataScriptsTriggert])
        const data = useQuery({
                url:`${window._env_.REACT_APP_API_URL}/v1/catalog/requests/${configId}/executeScript/${scriptName(dataDefinition)}?customerId=${customers}`,
                requestOptions:
                    {
                        method:"POST",
                        headers:{"content-type":"application/json"},
                        body:JSON.stringify(body)
                    }
            },
            [dependOn,dependOnVal,init,reload,reRunDataScriptsTriggert], init && scriptName(dataDefinition) )


        React.useEffect(()=> {
            //DATA (SCRIPT) CHANGED

            if (data){
                setInit(false);
                setFirstLoad(true);
            }

            if (data.hasOwnProperty("scriptResult") && data.scriptResult.length===0 && !curVal && !dynDataInputs){

                onChange(undefined);
            }


        },[data]);

        React.useEffect(()=> {


            if (cValue!==undefined && Array.isArray(cValue) && dynDataInputs){
                //check if current value is in dynDataInputs - else reset!

                if (dynDataInputs.filter(data=>cValue.some(e=>e.value===data.value)).length===0){
                    onChange(undefined);
                }


            }
            if (dependOnVal!==undefined && dependOn===undefined){
                onChange(undefined);
            }else{
                if (checkRunQuery(dataDefinition,reRunDataScriptsTriggert,firstLoad,dependOn,elPath,dependOnVal,setBody,data)){

                    if (dataDefinition.hasOwnProperty("dependOn")){
                        setInit(true);
                        setDependOnVal(dependOn);
                    }

                }
            }




        },[dependOn,dynDataInputs]);

    React.useEffect(()=> {
        if((!(cValue) || cValue.length==0) && required){
            setValid(false) ;
        }else{
            setValid(true) ;
        }

    },[cValue,required])

    const [valid,setValid]=React.useState(()=>{

        if(!cValue && required){

            return false;
        }else{
            return true;
        }
    });

    if (hiddenEval){
        return (
            <Placeholder {...rest}/>
        )
    }
    return(
        <div>
            <br/>

            {
                (dataDefinition)
                    ?(<Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}><>
                            <Text type={( required && !valid)?("danger"):("")} strong>{( required && !valid)?("* " + label):(label)}</Text>
                        </></Col><Col xs={23} sm={23} md={23} lg={23} xl={23}>
                        <Select
                            mode={"multiple"}
                            showSearch

                            loading={init}
                            filterOption={false}
                            onSearch={handleQuery}
                            placeholder={placeholder||"Please select"}
                            value={cValue}
                            onChange={(v) => {
                                if (v){
                                    setValid(true);
                                }

                                if (max && v.length>max){
                                   const newArray=v.filter((e,i)=>i!==v.length-2)

                                    onChange(newArray)
                                }else{
                                    onChange(v)
                                }
                                }}
                            labelInValue
                            style={{ width: '100%' }}
                        >

                            {(data && data.hasOwnProperty("scriptResult")) &&
                            data.scriptResult.map((d,k)=>(<Option key={"option-"+d.value+"-"+k} value={d.value}>{d.label}</Option>))}
                        </Select>
                    </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                            {(!hideRefresh)&&<Button  style={{ width: '100%' }} icon={<ReloadOutlined />} onClick={()=>{setReload(!reload); setInit(true)}}></Button>}
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}><div className={"divSpacer"}></div></Col>
                    </Row>)
                    :(<Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}><>
                            <Text type={( required && !valid)?("danger"):("")} strong>{( required && !valid)?("* " + label):(label)}</Text>
                        </></Col><Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Select
                            mode={"multiple"}

                            placeholder={placeholder||"Please select"}
                            value={cValue}
                            onChange={(v) => {if (v){setValid(true);}onChange(v);}}
                            labelInValue
                            style={{ width: '100%' }}
                        >

                            {(dynDataInputs && dynDataInputs.length>0)
                                ?(
                                    dynDataInputs.map((d,k)=>(<Option key={"option-"+d.value+"-"+k} value={d.value}>{d.label}</Option>))
                                )
                                :(
                                    (dataInputs && dataInputs.length >0) &&
                                    dataInputs.map((d,k)=>(<Option key={"option-"+d.value+"-"+k} value={d.value}>{d.label}</Option>))
                                )
                            }
                        </Select>
                    </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}><div className={"divSpacer"}></div></Col>
                    </Row>)
            }


        </div>
    )

})


