import {Select, Button, Row, Col, Typography, message} from 'antd';
import useQuery from "../util/useQuery";
import {ReloadOutlined} from  "@ant-design/icons";


import React from "react";
import _ from "lodash";
import {checkRunQuery, debugLog, reqBody, scriptName} from "../util/componentUtils";
import Placeholder from "../Placeholder/Placeholder";
import {smileCatalogAPIcall} from "../util/redux/asyncActions";


const {Option}=Select;
const {Text}=Typography;


function initState({cValue,dataDefinition,elPath}){
    return {
        curVal:"",
        reload:false,
        firstRender:true,
        body:reqBody(dataDefinition,undefined,elPath),
        options:[],
        init:true,
        triggerDataCheck:false,
        checkVal:undefined,
        lValue:undefined
    }
}
function reducer(state,action){

    switch (action.type){
        case 'checkData':
            if (action.cValue){
                state.triggerDataCheck=true
                state.checkVal=action.cValue && action.cValue.value
            }

            return {...state}

        case 'checkDone':
            state.triggerDataCheck=false
            return {...state}

        case 'searchData':

            state.body=action.body
            state.init = true
            if (action.curVal){
                state.curVal=action.curVal
            }
            return {...state}
        case 'searchDone':
            state.init = false




            if (action.scriptResult && action.scriptResult.length>0){
                console.log(action.cValue)
                if (action.cValue && action.cValue.value && !action.scriptResult.find(e=>e.value===action.cValue.value)){
                    state.triggerDataCheck=true
                    state.checkVal=action.cValue && action.cValue.value

                }
                state.options=action.scriptResult
            }else{
                state.options=[]
                action.onChange(undefined)
            }
            return {...state}
        case 'setCurVal':
            state.curVal=action.curVal
            return {...state}
        case 'setOptions':
            state.options=action.options

            return {...state}
        case 'valSelected':
            state.lValue=action.value
            action.onChange(action.value)
            return state

        case 'reset':
            return initState(action)
        default:
            console.log("unknown action type")
            return state
    }
    return state

}


export default  React.memo((props) =>{

    const {label,id,onChange,dataDefinition,configId,hiddenEval,dataInputs,elPath,cValue,dependOn,required,dynDataInputs,readonly,placeholder,reRunDataScriptsTriggert,customers,history,userManager,...rest}=props;



    const [dependOnVal,setDependOnVal] = React.useState(undefined);
    const [firstLoad,setFirstLoad]=React.useState(false);
    const [body,setBody]=React.useState(reqBody(dataDefinition,cValue && cValue.value,elPath));


    const [state,lDispatch]=React.useReducer(reducer,{cValue,dataDefinition,elPath},initState)




    /** START - functions for search **/
    const delayedQuery = React.useCallback(
        _.debounce(q => {

            lDispatch({type:"searchData",body:reqBody(dataDefinition,q,elPath),init:true,curVal:q})
        }, 500),
        [], // will be created only once initially
    );
    const handleQuery = (q)=>{

        lDispatch({type:"setCurVal",curVal:q})
        delayedQuery(q);
    }

    /** END - functions for search **/


    /** Start - first initial render*/
    /*React.useEffect(()=>{
        if (firstRender){

            if (cValue===undefined){
                onChange(undefined);
            }else if(cValue && cValue.value){

                //handleQuery(cValue.value) // hier kommt der fehler her.
                lDispatch({type:"checkData"})
                lDispatch({type:'setCurVal',curVal:""})

            }
            setFirstRender(false);
        }
    },[state.firstRender]) */
    /** End - first initial render*/

    /** START - Trigger Data Check useEffect **/

     React.useEffect(()=>{


         if (state.triggerDataCheck  && scriptName(dataDefinition)){

             const checkVal=state.checkVal;

             debugLog(`checking data for field ${elPath}: ${JSON.stringify(checkVal)}`)
            smileCatalogAPIcall({url:`${window._env_.REACT_APP_API_URL}/v1/catalog/requests/${configId}/executeScript/${scriptName(dataDefinition)}?customerId=${customers}&check${id}`,
                 requestOptions:             {
                 method:"POST",
                     headers:{"content-type":"application/json"},
                 body:JSON.stringify(reqBody(dataDefinition,checkVal,elPath))
                },userManager,history
            }).then(checkResult=> {
                if (  checkResult && checkResult.scriptResult ){


                    debugLog(`checking data for field ${elPath}`)

                    if (!( checkResult.scriptResult.find(item=> item.value===checkVal))){
                        message.error(`Feld ${id}: ungültiger Wert ${JSON.stringify(checkVal)} wurde entfernt`)
                        debugLog(`data for field ${elPath} is invalid. ${JSON.stringify(checkVal)}`)
                        lDispatch({type:"valSelected",onChange,val:undefined})
                        if (required){
                            setValid(false)
                        }
                    }else{

                        debugLog(`data for field ${elPath} is valid. ${JSON.stringify(checkVal)}`)


                        if (!state.options.find(item=>item.value===checkVal)){
                            lDispatch({type:"setOptions",options:[...state.options,cValue]})
                        }


                        if (required){
                            setValid(true)
                        }



                    }

                }
                lDispatch({type:'checkDone'})
            })


         }


     }
        ,
        [state.triggerDataCheck], // will be created only if triggerDataCheck changes
    );
    /** END - Trigger Data Check useEffect **/


     /** START - Re Run Data Script (example: customer change) **/

    React.useEffect(()=>{

        if (reRunDataScriptsTriggert){

            lDispatch({type:"searchData",body:reqBody(dataDefinition,undefined,elPath),init:checkRunQuery(dataDefinition,reRunDataScriptsTriggert,firstLoad,dependOn,elPath,dependOnVal,setBody)})
        }
    },[reRunDataScriptsTriggert,id])
    /** END Re Run Data Script (example: customer change) **/








    const data = useQuery({
        url:`${window._env_.REACT_APP_API_URL}/v1/catalog/requests/${configId}/executeScript/${scriptName(dataDefinition)}?customerId=${customers}`,
        requestOptions:
            {
                method:"POST",
                headers:{"content-type":"application/json"},
                body:JSON.stringify(state.body)
            }
        },
        [dependOn,dependOnVal,state.init,state.body,reRunDataScriptsTriggert], state.init && scriptName(dataDefinition))




    React.useEffect(()=> {
        //DATA (SCRIPT) CHANGED


        if (data && data.scriptResult){

            lDispatch({type:'searchDone',scriptResult:data.scriptResult,onChange,cValue})
        }

      /*  if (data.hasOwnProperty("scriptResult") && data.scriptResult.length>0){


                setOptions(data.scriptResult)

        }

        if (data.hasOwnProperty("scriptResult") && data.scriptResult.length===0) {

            setOptions([])
            onChange({value: undefined, key: undefined});
        }*/


    },[data,id]);






    React.useEffect(()=> {


        if (cValue!==undefined && state.curVal === "" && dataDefinition && state.lValue !==cValue){

            lDispatch({type:'checkData',cValue})
            //check if current value is in data - else reset!

           /* if (!state.firstRender && data && data.hasOwnProperty("scriptResult") && data.scriptResult.filter(item=>item.value==cValue.value).length===0){
               lDispatch({type:'checkData'})
                //setCurrentValue("");
            }*/


        }else if (cValue!==undefined && dataInputs && Array.isArray(dataInputs)){
            if (dataInputs  && dataInputs.filter(item=>item.value==cValue.value).length===0){
                if (required){
                    setValid(false);
                }

               // onChange(undefined);
                lDispatch({type:'setCurVal',curVal:""});
            }
        }

        if( cValue  && cValue.value && required &&  ((data && data.hasOwnProperty("scriptResult") && data.scriptResult.filter(item=>item.value==cValue.value).length>0) || ( Array.isArray(dataInputs) && dataInputs.filter(item=>item.value==cValue.value).length>0 ))){

            setValid(true) ;
        }else{

            lDispatch({type:'checkData',cValue})
        }


    },[cValue,required,data])


 /** START -  Effect for Static Select **/
    React.useEffect(()=> {


        if (cValue!==undefined && dynDataInputs){
            //check if current value is in dynDataInputs - else reset!
            if (dynDataInputs.filter(data=>data.value===cValue.value).length===0){

                onChange({value:undefined,key:undefined});
            }


        }





    },[dynDataInputs]);

    /** END -  Effect for Static Select **/


    const [valid,setValid]=React.useState(()=>{
        if(!(cValue)>0 && required){
            return false;
        }else{
            return true;
        }
    });


    if (hiddenEval){
        return (
            <Placeholder {...rest}/>
        )
    }
    return(
        <div>
            <br/>

                {
                    (dataDefinition)
                    ?(<Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>  <>
                            <Text type={( required && !valid)?("danger"):("")} strong>{( required && !valid)?("* " + label):(label)}</Text>
                        </></Col><Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Select
                            showSearch
                            searchValue={state.curVal}
                            loading={state.init}
                            disabled={readonly}
                            //optionFilterProp="children"
                            filterOption={false}//{(input,option)=>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            placeholder={placeholder||"Please select"}
                            value={cValue}
                            onSearch={handleQuery}
                            onChange={
                                (v) => {
                                    if (v){
                                        setValid(true);
                                    }
                                    lDispatch({type:'valSelected',value:v,onChange})

                                }
                            }
                            labelInValue
                            style={{ width: '100%' }}
                        >

                            {(state.options && state.options.length>0) &&
                            state.options.map((d,k)=>(<Option key={"option-"+d.value+"-"+k} value={d.value}>{d.label}</Option>))}
                        </Select>
                        </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={24}><div className={"divSpacer"}></div></Col></Row>)
                    :(<Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}><>
                            <Text type={( required && !valid)?("danger"):("")} strong>{( required && !valid)?("* " + label):(label)}</Text>
                        </></Col><Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input,option)=>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            placeholder={placeholder||"Please select"}
                            value={cValue}
                            disabled={readonly}
                            onChange={(v) => {if (v){setValid(true);}onChange(v);}}
                            labelInValue
                            style={{ width: '100%' }}
                        >
                            {(dynDataInputs && dynDataInputs.length>0)
                                ?(
                                    dynDataInputs.map((d,k)=>(<Option key={"option-"+d.value+"-"+k} value={d.value}>{d.label}</Option>))
                                )
                                :(
                                    (dataInputs && dataInputs.length >0) &&
                                    dataInputs.map((d,k)=>(<Option key={"option-"+d.value+"-"+k} value={d.value}>{d.label}</Option>))
                                )
                            }

                        </Select>
                    </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}><div className={"divSpacer"}></div></Col>
                        </Row>)
                }







        </div>
    )

})
