import {Col, DatePicker, Row, Typography} from 'antd';
import React from "react";
import jp from "jsonpath";
import useQuery from "../util/useQuery";
import Placeholder from "../Placeholder/Placeholder";
import {checkRunQuery, translateParams} from "../util/componentUtils";
import {smileCatalogAPIcall} from "../util/redux/asyncActions";
import moment from "moment";
import store from "../util/redux";
const {RangePicker} =DatePicker;
const {Text}=Typography;

export default function Switch(props) {
    const {label,onChange,cValue,dependOn,hiddenEval,elPath,dataDefinition,configId,required,customers,reRunDataScriptsTriggert,allowedDatesScript,userManager,history,showTime,...rest}=props;
    const [allowedDates,setAllowedDates]=React.useState({});

    React.useEffect(()=> {
        if (  allowedDatesScript){
            const parsedParams = translateParams(allowedDatesScript.params,elPath);
            smileCatalogAPIcall({url:`${window._env_.REACT_APP_API_URL}/v1/catalog/requests/${configId}/executeScript/${allowedDatesScript.script}?customerId=${customers}`,
                requestOptions:             {
                    method:"POST",
                    headers:{"content-type":"application/json"},
                    body:JSON.stringify({params:{...parsedParams,currenValue:cValue},requestData:store.getState().request.values})
                },userManager,history
            }).then(result=>
            {
                if (result.scriptResult ){
                    setAllowedDates(result.scriptResult)
                }else{
                    setAllowedDates({})
                }
            })



        }
        if(!cValue){
            onChange();
        }
    },[reRunDataScriptsTriggert,allowedDatesScript]);




    const [script,setScript]=React.useState(()=> {

            if (dataDefinition){
                return dataDefinition.script
            }else{
                return undefined;
            }
        }

    );

    const [init,setInit]=React.useState(()=>{
        if (dataDefinition){
            return true
        }else{
            return false
        }
    });

    const [body,setBody]=React.useState(()=> {

            if (dataDefinition){
                return {
                    params:dataDefinition.params,
                    requestData:{}
                }
            }else{
                return undefined;
            }
        }

    );


    const [reload,setReload]=React.useState(false);
    const [valid,setValid]=React.useState(()=>{
        if(!cValue && required){
            return false;
        }else{
            return true;
        }
    });

    function  checkDates(current) {
        if (allowedDatesScript){

            if (allowedDates.start && moment(allowedDates.start).isValid()){
                if (moment(allowedDates.start).isAfter(current,'day')){
                    return true
                }
            }
            if (allowedDates.end && moment(allowedDates.end).isValid()){
                if (moment(allowedDates.end).isBefore(current,'day')){
                    return true
                }

            }
            if (allowedDates.disabled && Array.isArray(allowedDates.disabled)){

                if (allowedDates.disabled.find(date=> moment(date).isSame(current,'day'))){
                    return true
                }

            }
            return false


        }else{
            return false
        }
    }

    const data = useQuery({
            url:`${window._env_.REACT_APP_API_URL}/v1/catalog/requests/${configId}/executeScript/${script}?customerId=${customers}`,
            requestOptions:
                {
                    method:"POST",
                    headers:{"content-type":"application/json"},
                    body:JSON.stringify(body)
                }
        },
        [props,reload,reRunDataScriptsTriggert], init)

    React.useEffect(()=> {
        if (data){
            setInit(false);
            if (data.hasOwnProperty("scriptResult")){

                onChange(data.scriptResult);
            }
        }


    },[data]);

    React.useEffect(()=> {
        if(!cValue && required){
            setValid(false) ;
        }else{
            setValid(true) ;
        }

    },[cValue,required])

    if (hiddenEval){
        return (
            <Placeholder {...rest}/>
        )
    }
    return(
        <div>
            <br/>
            <>
                <Text type={( required && !valid)?("danger"):("")} strong>{( required && !valid)?("* " + label):(label)}</Text>
            </>
            <br/>
            <RangePicker
                value={cValue && cValue.map(date=>moment(date))}
                showTime={showTime}
                disabledDate={checkDates}
                onChange={(v) => {if (v){setValid(true);} onChange(v.map(date=>date.toISOString()));}}
                style={{ width: '100%' }}
            />
            <div className={"divSpacer"}></div>

        </div>
    )

}

