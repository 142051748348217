import {Typography,Upload, message,Space} from 'antd';
import React, {useState} from "react";

import { InboxOutlined } from '@ant-design/icons';
import {AuthConsumer} from "../Auth/AuthProvider";
import axios from "axios";


const {Text} = Typography;
const { Dragger } = Upload;
export default function Attachment(props) {
    const {label,onChange,required,cValue,hiddenEval,filesize,filetypes,min,max}=props;
    const [token, setToken] = useState("");


    React.useEffect(()=> {
        if(!cValue){
            onChange();
        }
    },[props]);

    const [valid,setValid]=React.useState(()=>{
        if (cValue&&cValue.length > 0){
            return true;
        }else{
            return false
        }
    })

    React.useEffect(()=> {
        if (cValue&&cValue.length > 0){
            setValid(true) ;
        }else{
            setValid(false) ;
        }

    },[cValue,required])



    const uploadProps = {
        name: 'file',
        multiple: true,
        headers:{authorization:"bearer "+token},
        action: window._env_.REACT_APP_API_URL+'/v1/attachments',
        onChange(info) {

            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            console.log(info);
            if (status === 'done') {
                if (info && info.file && info.file.response  && info.file.response.file){
                    message.success(`${info.file.name} Datei erfolgreich hochgeladen.`);
                    if (info.fileList ) {

                        onChange(info.fileList.map(f=>f.response && f.response.file));
                    }
                }


            } else if (status === 'error') {
                message.error(`${info.file.name} Datei upload fehlgeschlagen.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
        onRemove: (file)=>
        {
        onChange(cValue.filter(f=>f.id!==file.response.file.id))
        axios.delete(window._env_.REACT_APP_API_URL+"/v1/attachments/"+file.response.file.id,{
                headers:{authorization:"bearer "+token}
            }
        );

        }
    };



    if (hiddenEval) {
        return null
    }else{
        return(
            <div>
                <AuthConsumer>
                    {({getUser})=>{
                        getUser().then(user=>{
                            setToken(user.access_token);
                        })
                    }}
                </AuthConsumer>
                <br/>

                    <Text type={( required && !valid)?("danger"):("")} strong>{( required && !valid )?("* " + label):(label)}</Text><br/>
                    <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Klicken Sie hier, oder ziehen Sie Dateien in diesen Bereich</p>

                    </Dragger>


                <div className={"divSpacer"}></div>


            </div>
        )
    }


}

