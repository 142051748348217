import {Col, DatePicker, Row, Typography} from 'antd';
import React from "react";
import jp from "jsonpath";
import useQuery from "../util/useQuery";
import Placeholder from "../Placeholder/Placeholder";
import moment from "moment";
import {checkRunQuery, reqBody, scriptName, translateParams} from "../util/componentUtils";
import {smileCatalogAPIcall} from "../util/redux/asyncActions";
import store, {setDataTrigger} from "../util/redux";
import {useDispatch} from "react-redux";

const {Text}=Typography;

export default function DateField(props) {
    const {label,onChange,cValue,hiddenEval,dependOn,elPath,dataDefinition,configId,required,showTime,customers,reRunDataScriptsTriggert,allowedDatesScript,userManager,history,readonly,...rest}=props;


    const dispatch=useDispatch();
    const [allowedDates,setAllowedDates]=React.useState({});


    React.useEffect(()=> {


        if (  allowedDatesScript){
            const parsedParams = translateParams(allowedDatesScript.params,elPath);
             smileCatalogAPIcall({url:`${window._env_.REACT_APP_API_URL}/v1/catalog/requests/${configId}/executeScript/${allowedDatesScript.script}?customerId=${customers}`,
                requestOptions:             {
                    method:"POST",
                    headers:{"content-type":"application/json"},
                    body:JSON.stringify({params:{...parsedParams,currenValue:cValue},requestData:store.getState().request.values})
                },userManager,history
            }).then(result=>
             {
                 if (result.scriptResult ){
                    setAllowedDates(result.scriptResult)
                 }else{
                     setAllowedDates({})
                 }
                 dispatch(setDataTrigger({elPath,value:false}))
             })



        }
        if(!cValue){
            onChange();
        }
    },[reRunDataScriptsTriggert,allowedDatesScript]);


    const [script,setScript]=React.useState(()=> {

            if (dataDefinition){
                return dataDefinition.script
            }else{
                return undefined;
            }
        }

    );

    const [init,setInit]=React.useState(()=>{
        if (dataDefinition){
            return true
        }else{
            return false
        }
    });

    const [body,setBody]=React.useState(()=> {

            if (dataDefinition){
                return {
                    params:dataDefinition.params,
                    requestData:{}
                }
            }else{
                return undefined;
            }
        }

    );


    const [reload,setReload]=React.useState(false);
    const [valid,setValid]=React.useState(()=>{
        if(cValue&& required){
            return false;
        }else{
            return true;
        }
    });

    React.useEffect(()=> {


        if(!cValue && required){
            setValid(false) ;
        }else{
            setValid(true) ;
        }

    },[cValue,required])


    const data = useQuery({
            url:`${window._env_.REACT_APP_API_URL}/v1/catalog/requests/${configId}/executeScript/${script}?customerId=${customers}`,
            requestOptions:
                {
                    method:"POST",
                    headers:{"content-type":"application/json"},
                    body:JSON.stringify(body)
                }
        },
        [props,reload,reRunDataScriptsTriggert], init)

    React.useEffect(()=> {
        if (data){
            setInit(false);
            dispatch(setDataTrigger({elPath,value:false}))
            if (data.hasOwnProperty("scriptResult")){

                onChange(data.scriptResult);
            }
        }


    },[data]);

    if (hiddenEval){
        return (
            <Placeholder {...rest}/>
        )
    }

     function  checkDates(current) {
       if (allowedDatesScript){

           if (allowedDates.start && moment(allowedDates.start).isValid()){

               if (moment(allowedDates.start).isAfter(current,'day')){
                   return true
               }
           }
           if (allowedDates.end && moment(allowedDates.end).isValid()){
               if (moment(allowedDates.end).isBefore(current,'day')){
                   return true
               }

           }
           if (allowedDates.disabled && Array.isArray(allowedDates.disabled)){

              if (allowedDates.disabled.find(date=> moment(date).isSame(current,'day'))){
                  return true
              }

           }
           return false


        }else{
            return false
        }
    }




    return(
        <div>
            <br/>
            <>
                <Text type={( required && !valid)?("danger"):("")} strong>{( required && !valid)?("* " + label):(label)}</Text>
            </>
            <br/>
            <DatePicker
                value={(cValue)?moment(cValue):undefined}
                showTime={showTime}
                disabledDate={checkDates}
                disabled={readonly}
                onChange={(v) => {if (v){setValid(true);}onChange(v.toISOString());}}
                style={{ width: '100%' }}
            />
            <div className={"divSpacer"}></div>

        </div>
    )

}

