import React from "react";
import { AuthConsumer } from "./AuthProvider";

import ManyosSpinner from "../ManyosSpinner/ManyosSpinner";


export const Logout = () => (
    <AuthConsumer>
        {({ logout }) => {
            logout();
            return <ManyosSpinner/>;
        }}
    </AuthConsumer>
);
