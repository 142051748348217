import jp from "jsonpath";
import store from "../../components/util/redux/index";


export function debugLog(string){
    if (window._env_.REACT_APP_LOGLEVEL=="DEBUG"){
        console.log(string);
    }
}

export function getSearchPath(searchPath,elPath){

    if (elPath === undefined){
        return undefined
    }
    let re = /\$CURRENT\[(.*?)]\$.*/g;

    let dependOn=searchPath;

    if (dependOn===undefined || typeof dependOn !== "string"){
        return undefined;
    }
    let blockOffsets=dependOn.match(re);


    let finStr = dependOn;

    if (blockOffsets && blockOffsets.length>0){

        blockOffsets.forEach(blockOffset=>{


            let offset = 0;
            re = /\[(.*?)]/g;
            let offsetMatch= blockOffset.match(re);

            if (offsetMatch && offsetMatch.length>0){


                offset = offsetMatch[0].slice(1,-1);

                finStr = finStr.replace(re,"");


            }
            const path=elPath.split(".");

            if(path[path.length-1].startsWith("instances[")){

                finStr= finStr.replace(/\$CURRENT\$/g, (path).join("."));
            }else{
                offset=offset-1;
                finStr= finStr.replace(/\$CURRENT\$/g, (path.slice(0,offset)).join("."));
            }





            if (path){


            }


        });



    }else{

        const path=elPath.split(".");


        if(path[path.length-1].startsWith("instances[")){

            finStr= finStr.replace(/\$CURRENT\$/g, (path).join("."));
        }else{

            finStr= finStr.replace(/\$CURRENT\$/g, (path.slice(0,-1)).join("."));
        }


    }
    if (finStr){

        return finStr;

    }else{
        return undefined
    }

}

export function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function evalQualification(values,qualification,elPath){
    try {
        //replace $CURRENT$ with parent path.

        let qual = getSearchPath(qualification,elPath);

        let re=/\$\{.*?\}/g
        const matches = qual.match(re)


        if (matches && matches.length > 0){
            qual.match(re).forEach(e=>{

                let val=jp.query(values,e.substring(2, e.length-1));

                const lVal = val[0];
                const nre = new RegExp(escapeRegExp(e));

                if (typeof lVal !== "boolean" && typeof lVal !=="undefined"){
                    qual = qual.replace(nre,`"${lVal}"`);
                }else{
                    qual = qual.replace(nre,lVal);
                }



            });

            return eval(qual);
        }
        return eval(qual);

    }catch(e){

       return false;
    }

}
export function getValFromStore(values,qualification,elPath){
    try {
        //replace $CURRENT$ with parent path.

        let qual = getSearchPath(qualification,elPath);


        let re=/\$\{.*?\}/g
        const matches = qual.match(re)


        if (matches && matches.length > 0){
            qual.match(re).forEach(e=>{

                let val=jp.query(values,e.substring(2, e.length-1));

                const lVal = val[0];
                const nre = new RegExp(escapeRegExp(e));


                    qual = qual.replace(nre,lVal);




            });

            return qual
        }
        return qual;

    }catch(e){

        return undefined;
    }

}


export function translateParams(params,elPath){
    try {
        //replace $CURRENT$ with parent path.


        let translated={}
        Object.keys(params).map((param)=>{
            let qual = getSearchPath(params[param],elPath);
            if (qual){

                let re=/\$\{.*?\}/g
                const matches = qual.match(re)


                if (matches && matches.length > 0){

                    qual.match(re).forEach(e=>{

                        let val=jp.query(store.getState().request.values,e.substring(2, e.length-1));
                        let path=jp.paths(store.getState().request.values,e.substring(2, e.length-1));

                        const lVal = val[0];
                        if (path.length>0){

                            const nre = new RegExp(escapeRegExp(e));
                            if (typeof lVal !== "boolean" && typeof lVal !=="undefined"){


                                qual = qual.replace(nre,lVal);

                            }else{

                                qual = qual.replace(nre,lVal||"");
                            }

                        }




                    });


                }

                translated[param]=qual;
            }else{
                translated[param]=params[param]
            }
        });





        return translated;

    }catch(e){
        console.log(e)
        return false;
    }

}


export function checkRunQuery(dataDefinition,reRunDataScriptsTriggert,firstLoad,dependOn,elPath,dependOnVal,setBody,data,){
    if (dataDefinition){

        if (!(dataDefinition.hasOwnProperty("dependOn"))){
            if (!firstLoad || reRunDataScriptsTriggert){
                return true;
            }else{
                return false;
            }
        }else{
            try{

                if((dependOn  && dependOn !== dependOnVal) ||  (dependOn !== dependOnVal) && dependOn===undefined ){
                    let tBody={
                        params:JSON.parse(JSON.stringify(dataDefinition.params)),
                        requestData: {},
                        scope:dataDefinition.scope
                    };
                    tBody.params.currentValue=dependOn;

                    setBody(
                        tBody
                    );

                    return true;
                }
            }catch(e){
                console.log(e);
                return false;
            }


            return false;
        }

    }else{
        return false
    }
}



export function reqBody(dataDefinition,curVal,elPath) {

    if (dataDefinition){
        let tmp = Object.assign({},dataDefinition.params);
        if (elPath){
            tmp = translateParams(tmp,elPath)
        }


        if (typeof curVal !== "undefined" && curVal !=="" ){
            tmp.currentValue={value:curVal,id:curVal};

        }
            return {
                params:tmp,
                requestData: store.getState().request.values,
                scope:dataDefinition.scope
            }

    }else{
        return undefined;
    }
}

export  function checkRegex(value,regex,required){
    if (regex && value){
        const val=value;
        const re=new RegExp(regex,"gm");

        return re.test(val);

    } else if ((value===undefined || value.length==0) && required){

        return false;
    }
    else{

        return true;
    }
}

export function scriptName(dataDefinition){

    if (dataDefinition){
        return dataDefinition.script || dataDefinition.id
    }else{
        return undefined;
    }
}
