import {Checkbox , Space,Typography} from 'antd';
import React from "react";
import jp from "jsonpath";
import Placeholder from "../Placeholder/Placeholder";

const CheckboxGroup = Checkbox.Group;

const {Text} = Typography;

export default function CheckBox(props) {
    const {label,onChange,values,elPath,required,dataInputs,cValue,dependOn,hiddenEval,dynDataInputs,...rest}=props;


    React.useEffect(()=> {
        if(!cValue){
            onChange();
        }
    },[props]);

    const [valid,setValid]=React.useState(()=>{
        if (cValue&&cValue.length > 0){
            return true;
        }else{
            return false
        }
    })

    React.useEffect(()=> {
        if (cValue&&cValue.length > 0){
            setValid(true) ;
        }else{
            setValid(false) ;
        }

    },[cValue,required])

    if (hiddenEval) {
        return (
            <Placeholder {...rest}/>
        )
    }else{
        return(
            <div>
                <br/>
                <Space>
                    <Text type={( required && !valid)?("danger"):("")} strong>{( required && !valid )?("* " + label):(label)}</Text>
                    <CheckboxGroup options={(dataInputs && dataInputs.map(e=>e.label))} value={cValue} onChange={(e)=>{if (e.length>0){setValid(true)}else{setValid(false)};onChange(e)}} />

                </Space>
                <div className={"divSpacer"}></div>


            </div>
        )
    }


}

