
import React, {Component,Suspense} from 'react';
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import {Provider} from "react-redux";
import store from "./components/util/redux/index";

import './assets/css/custom-theme.css';
import './assets/css/smilecatalog.css';
import './i18nextConf';

// core components
import Catalog from "./layouts/Catalog.js";

import UIBuilder from "./layouts/UIBuilder.js";
import DevStudio from "./layouts/Devstudio.js";

import { Callback } from "./components/Auth/Callback";
import { Logout } from "./components/Auth/Logout";
import { LogoutCallback } from "./components/Auth/LogoutCallback";
import { PrivateRoute } from "./components/Auth/PrivateRoute";
import { AdminRoute } from "./components/Auth/AdminRoute";
import { SilentRenew } from "./components/Auth/SilentRenew";
import { AuthProvider } from "./components/Auth/AuthProvider";
import './components/util/wdyr';
import {notification} from "antd"; // <--- first import
const hist = createBrowserHistory();


let link = document.createElement('link');

// set the attributes for link element
link.rel = 'stylesheet';

link.type = 'text/css';


link.href = '/customer/customer.css';

// Get HTML head element to append
// link element to it
document.getElementsByTagName('HEAD')[0].appendChild(link);


if (window._env_.REACT_APP_TITLE){
    document.title = window._env_.REACT_APP_TITLE
}


export class Auth extends Component {
    render(){
        return(
            <Suspense fallback="...">
            <Provider store={store}>
                <AuthProvider  >
                    <Router history={hist}>

                            <Switch>
                                <Route exact={true} path="/signin-oidc" component={Callback} />
                                <Route exact={true} path="/logout" component={Logout} />
                                <Route exact={true} path="/logout/callback" component={LogoutCallback} />
                                <Route exact={true} path="/silentrenew" component={SilentRenew} />
                                <PrivateRoute path="/catalog" component={Catalog} />
                                <AdminRoute path="/uibuilder" component={UIBuilder} />
                                <AdminRoute path="/devstudio" component={DevStudio} />
                                <Redirect from="/" to="/catalog/browse" />

                            </Switch>

                    </Router>
                </AuthProvider>
            </Provider>
            </Suspense>
        );

    }
}

ReactDOM.render(
  <Auth/>,
  document.getElementById("root")
);
